<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- Modal -->
  <teleport to='body'>
    <div
      class="modal fade"
      :id="idModal"
      :class="classModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title fw-600" id="exampleModalLongTitle">{{ modalTitle }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" :class="classButtonClose" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      required: true,
      default: "Model Title",
    },
    buttonCloseText: {
      type: String,
      required: false,
      default: "Close",
    },
    buttonSaveText: {
      type: String,
      required: false,
      default: "Save changes",
    },
    idModal: {
      type: String,
      required: true,
    },
    classModal: {
      type: String,
      required: false,
      default: "modal"
    },
    classButtonClose: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleCloseModal(){
      const btn = document.querySelector("."+this.classButtonClose);
      btn.click();
    }
  }
}
</script>

<style scoped>
.close {
  transform: translateX(0);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
</style>