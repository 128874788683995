import { format } from 'date-fns';
import { timeService } from "@/services";
export const summaryService = {
  updateTimeLine,
  updateFinishTimeHoursChanged,
  updateFinishTimeDaysChanged,
  updateScaleChanged,
  updateChangeTime,
  updateAfterChangeWorkingHours,
};

function updateTimeLine(object, finish_date, settings, totalDays) {
  object.start_date = format(timeService.addDaysFollowWeekdays(finish_date, finish_date, settings, 2), "yyyy-MM-dd");
  object.finish_date = object.start_date;
  const total_days = parseFloat(totalDays);
  if (!isNaN(total_days)) object.scale = ((1 / parseFloat(totalDays))*100).toString();
  object.days = "1";
  object.hours = settings.working_hours;
}

function updateFinishTimeHoursChanged(object, settings, totalDays, parent) {
  try {
    if(object.hours === "") object.hours = settings.working_hours;
    const days = parseFloat(object.hours) / parseFloat(settings.working_hours);
    object.days = days.toString();
    const finish_date = timeService.addDaysFollowWeekdays(new Date(object.start_date), 
    new Date(object.start_date), 
    settings, object.days);
    object.finish_date = format(finish_date, "yyyy-MM-dd");
    object.scale = ((days / totalDays)*100).toString();
  } catch (e) {
    console.log(e.message);
    updateTimeLine(object, 
      new Date(parent.master_plan_and_schedule.finish_date), 
      settings, totalDays);
  }
}

function updateFinishTimeDaysChanged(object, settings, totalDays, parent) {
  try {
    if(object.days === "") object.days = "1";
    const days = parseFloat(object.days);
    object.hours = (parseFloat(object.days) * parseFloat(settings.working_hours)).toString();
    const finish_date = timeService.addDaysFollowWeekdays(new Date(object.start_date), 
    new Date(object.start_date), 
    settings, object.days);
    object.finish_date = format(finish_date, "yyyy-MM-dd");
    object.scale = ((days / totalDays)*100).toString();
  } catch (e) {
    console.log(e.message);
    updateTimeLine(object, 
      new Date(parent.master_plan_and_schedule.finish_date), 
      settings, totalDays);
  }
}

function updateScaleChanged(object, settings, totalDays, parent) {
  try {
    if(object.scale === "") object.scale = "10";
    object.days = ((parseFloat(object.scale) / 100) * parseFloat(totalDays)).toString();
    updateFinishTimeDaysChanged(object, settings, totalDays, parent);
  } catch (e) {
    console.log(e.message);
    updateTimeLine(object, 
      new Date(parent.master_plan_and_schedule.finish_date), 
      settings, totalDays);
  }
}

function updateChangeTime(object, settings, totalDays, parent) {
  try {
    const start_date = object.start_date;
    const finish_date = object.finish_date;
    const days = timeService.calculateDaysBetween(new Date(start_date), new Date(finish_date), settings);
    object.days = days.toString();
    object.hours = (days*parseFloat(settings.working_hours)).toString();
    object.scale = ((days / parseFloat(totalDays)) * 100).toString();
  } catch (e) {
    console.log(e.message);
    updateTimeLine(object, 
      new Date(parent.master_plan_and_schedule.finish_date), 
      settings, totalDays);
  }
}

function updateAfterChangeWorkingHours(obj, settings, totalDays) {
  try {
    const hours = parseFloat(obj.hours);
    if(isNaN(hours)) throw new Error("Invalid hours");
    let days = hours / parseFloat(settings.working_hours);
    const current_date = new Date(obj.start_date);
    let finish_date = current_date;
    finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
    obj.finish_date = format(finish_date, "yyyy-MM-dd");
    obj.days = days.toString();
    obj.scale = ((days / totalDays) * 100).toString();
  } catch (e) {
    console.log(e.message);
  }
}