<template>
  <router-view />
</template>

<script>
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    var work = localStorage.getItem('work');
    var preparation = localStorage.getItem('preparation');
    var development = localStorage.getItem('development');
    var delivery = localStorage.getItem('delivery');
    if (work !== null) {
      store.state.work = JSON.parse(work);
    }
    if (preparation !== null) {
      store.state.preparation = JSON.parse(preparation);
    }
    if (development !== null) {
      store.state.development = JSON.parse(development);
    }
    if (delivery !== null) {
      store.state.delivery = JSON.parse(delivery);
    }
  },
  mounted() {
    // Thêm lắng nghe sự kiện beforeunload
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeUnmount() {
    // Xóa lắng nghe sự kiện beforeunload khi thành phần bị unmounted
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    handleBeforeUnload(event) {
      event.preventDefault();
      localStorage.setItem("work", JSON.stringify(this.$store.state.work));
      localStorage.setItem("preparation", JSON.stringify(this.$store.state.preparation));
      localStorage.setItem("development", JSON.stringify(this.$store.state.development));
      localStorage.setItem("delivery", JSON.stringify(this.$store.state.delivery));
    },
  },
}
</script>

<style></style>
