<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="main-header">
    <!-- Logo Header -->
    <div class="logo-header gap-3" data-background-color="blue">
      <application-logo class="cursor-pointer" data-toggle="modal" data-target="#exampleModalCenter" />
      <button class="navbar-toggler sidenav-toggler ml-auto" type="button" @click="handleToggleSideBarMobile">
        <span class="navbar-toggler-icon">
          <i class="icon-menu"></i>
        </span>
      </button>
      <button class="topbar-toggler more" @click="handleToggleHeader">
        <i class="icon-options-vertical"></i>
      </button>
      <div class="nav-toggle">
        <button class="btn btn-toggle toggle-sidebar" @click="handleToggleSideBar">
          <i class="icon-menu"></i>
        </button>
      </div>
    </div>
    <!-- End Logo Header -->

    <!-- Navbar Header -->
    <nav class="navbar navbar-header navbar-expand-lg" data-background-color="blue2">
      <div class="nav-toggle nav-toggle-2 d-none">
        <button class="btn btn-toggle toggle-sidebar" @click="handleToggleSideBar" data-toggle="collapse" data-target="collapse" aria-expanded="false" aria-label="Toggle navigation">
          <i class="icon-menu"></i>
        </button>
      </div>
      <div class="container-fluid">
        <div class="collapse" id="search-nav">
          <h2 class="text-white">{{ companyName }}</h2>
          <p class="company__info text-white">{{ companyAddress }}</p>
          <p class="company__info text-white">{{ companyContact }}</p>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
  </div>
</template>

<script setup>

</script>

<script>
import ApplicationLogo from "../components/ApplicationLogo.vue";
export default {
  components: {
    ApplicationLogo,
  },
  data() {
    return {
      sourceImageCompany: require("../assets/img/logo/logo.png"),
    };
  },
  computed: {
    companyName() {
      return process.env.VUE_APP_COMPANY_NAME;
    },
    companyAddress() {
      return process.env.VUE_APP_COMPANY_ADDRESS;
    },
    companyContact() {
      return process.env.VUE_APP_CONTACT;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if(window.innerWidth <= 991.2) {
        const sidebar = document.querySelector(".sidebar");
        const logo_header = document.querySelector(".logo-header");
        const main_panel = document.querySelector(".main-panel");
        const nav_toggle_2 = document.querySelector(".nav-toggle-2");
        if (sidebar.classList.contains("close")) {
          sidebar.classList.remove("close");
        }
        if (logo_header.classList.contains("close-header")) {
          logo_header.classList.remove("close-header");
          nav_toggle_2.classList.add("d-none");
        }
        if (main_panel.classList.contains("w-full")) {
          main_panel.classList.remove("w-full");
        }
      }
    },
    handleToggleSideBar() {
      const sidebar = document.querySelector(".sidebar");
      const logo_header = document.querySelector(".logo-header");
      const main_panel = document.querySelector(".main-panel");
      const nav_toggle_2 = document.querySelector(".nav-toggle-2");
      if (sidebar.classList.contains("close")) {
        sidebar.classList.remove("close");
      } else {
        sidebar.classList.add("close");
      }
      if (logo_header.classList.contains("close-header")) {
        logo_header.classList.remove("close-header");
        nav_toggle_2.classList.add("d-none");
      } else {
        logo_header.classList.add("close-header");
        nav_toggle_2.classList.remove("d-none");
      }
      if (main_panel.classList.contains("w-full")) {
        main_panel.classList.remove("w-full");
      } else {
        main_panel.classList.add("w-full");
      }
    },
    handleToggleSideBarMobile() {
      const sidebar = document.querySelector(".sidebar");
      if (sidebar.classList.contains("show-md-sidebar")) {
        sidebar.classList.remove("show-md-sidebar");
      } else {
        sidebar.classList.add("show-md-sidebar");
      }
    },
    handleToggleHeader() {
      const navbar = document.querySelector(".navbar");
      if (navbar.classList.contains("show-md-info")) {
        navbar.classList.remove("show-md-info");
      } else {
        navbar.classList.add("show-md-info");
      }
    },
  },
};
</script>

<style scoped>
#search-nav {
  max-width: 100%;
  align-items: left;
}
.company__info {
  color: var(--black);
}
.image_company {
  width: 100%;
  height: auto;
  border: 2px solid var(--info);
  border-radius: 5px;
}
.icon_camera {
  bottom: -5px;
  right: -5px;
  background-color: var(--light-info);
  color: white;
  padding: 5px;
  border-radius: 100%;
}
.error {
  font-family: var(--font-label);
  font-size: 14px;
  color: red;
  margin-left: 8px;
}
.input-error {
  border-color: red !important;
}
</style>
