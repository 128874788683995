import { format, addBusinessDays, addDays, isSunday, eachDayOfInterval, isSaturday } from 'date-fns';
export const timeService = {
  addDaysFollowWeekdays,
  setStartDateImplementation,
  setStartDateProgrammingAndTesting,
  setStartDateDelivery,
  calculateDaysBetween,
};

function addDaysFollowWeekdays(finish_date, current_date, settings, days) {
  if(settings.weekends === "0") finish_date = addBusinessDays(current_date, Math.ceil(days)-1);
  else {
    let tmp_days = Math.ceil(days)-1;
    while(tmp_days != 0) {
      finish_date = addDays(finish_date, 1);
      if(!isSunday(finish_date))  tmp_days--;
    }
  }
  return finish_date;
}

function setStartDateImplementation(state, rootState, settings) {
  if (Object.keys(rootState.development.requirements_analysis.task).length === 0 && state.preparation.finish_date) {
    let start_date = new Date(state.preparation.finish_date);
    start_date = addDaysFollowWeekdays(start_date, new Date(state.preparation.finish_date), settings, 2);
    rootState.development.requirements_analysis.start_date = rootState.development.requirements_analysis.finish_date = format(start_date, "yyyy-MM-dd");
  }
}

function setStartDateProgrammingAndTesting(state, settings) {
  if (Object.keys(state.programming_and_testing.task).length === 0 && state.requirements_analysis.finish_date) {
    let start_date = new Date(state.requirements_analysis.finish_date);
    start_date = addDaysFollowWeekdays(start_date, new Date(state.requirements_analysis.finish_date), settings, 2);
    state.programming_and_testing.start_date = state.programming_and_testing.finish_date = format(start_date, "yyyy-MM-dd");
  }
}

function setStartDateDelivery(state, rootState, settings) {
  if (Object.keys(rootState.delivery.delivery.task).length === 0 && state.finish_date) {
    let start_date = new Date(state.finish_date);
    start_date = addDaysFollowWeekdays(start_date, new Date(state.finish_date), settings, 2);
    rootState.delivery.delivery.start_date = rootState.delivery.delivery.finish_date = format(start_date, "yyyy-MM-dd");
  }
}

function calculateDaysBetween(start_date, finish_date, settings) {
  const allDays = eachDayOfInterval({ start: start_date, end: finish_date });
  let workingDays = allDays.filter(day => !isSunday(day)).length;
  if(settings.weekends === "0") {
    workingDays = allDays.filter(day => !isSaturday(day) && !isSunday(day)).length;
  }
  return workingDays;
}