import { format } from 'date-fns';
import { summaryService, timeService, service } from "@/services";
const Work = {
  namespaced: true,
  state: {
    project_details: {
      estimation_base: "0",
      working_hours: "8",
      weekends: "0", //0 is Saturday and Sunday, 1 is Sunday
      date_format: "dd-MM-yyyy",
    },
    summary: {
      project_name: {
        project_name: "",
        version: "",
        estimation_date: ""
      },
      project_short_description: "",
      assumptions_and_constraints: "",
      question: "",
      quotation: {
        currency: "Euro",
        total_cost: "",
        development_cost: {
          rate: "0",
          total_in_man_hours: "",
          total_in_man_days: "",
          total_in_man_months: "",
          development_cost: ""
        },
        support_cost: "",
        license_cost: "",
        other_cost: "",
      },
      warranty: {
        start_date: "",
        finish_date: "",
        hours: "",
        days: "",
        scale: "",
      },
      project_managerment_sqa: {
        project_management: {
          hours: "0",
          days: "0",
          scale: "0",
        },
        sqa: {
          hours: "0",
          days: "0",
          scale: "0",
        }
      },
      master_plan_and_schedule: {
        start_date: "",
        first_demo: "",
        finish_date: "",
        UAT: ""
      }
    },
  },
  mutations: {
    updateFinishDaysDevelopment(state, block) {
      const { rootState, totalDays } = block;
      const settings = state.project_details;
      let finish_date = new Date(-8640000000000000);
      let compare_finish_date = new Date(-8640000000000000);
      let finish_date_preparation = new Date(rootState.preparation.preparation.finish_date);
      let finish_date_development = new Date(rootState.development.finish_date);
      let finish_date_delivery = new Date(rootState.delivery.delivery.finish_date);
      if(!isNaN(finish_date_preparation)){
        finish_date = finish_date_preparation;
      }
      if(!isNaN(finish_date_development)){
        if(finish_date_development > finish_date) finish_date = finish_date_development;
      }
      if(!isNaN(finish_date_delivery)){
        if(finish_date_delivery > finish_date) finish_date = finish_date_delivery;
      }
      if(finish_date == compare_finish_date) state.summary.warranty.start_date = "";
      else {
        state.summary.master_plan_and_schedule.finish_date = format(finish_date, "yyyy-MM-dd");
        if(state.summary.master_plan_and_schedule.UAT) {
          const start_date_warranty = new Date(state.summary.master_plan_and_schedule.UAT);
          if(!state.summary.warranty.start_date)
            summaryService.updateTimeLine(state.summary.warranty, start_date_warranty, settings, totalDays);
          else {
            state.summary.warranty.start_date = 
            format(
              timeService.addDaysFollowWeekdays(start_date_warranty, start_date_warranty, settings, 2), 
              "yyyy-MM-dd"
            );
            if(state.project_details.estimation_base === "0")
              summaryService.updateFinishTimeHoursChanged(state.summary.warranty, state.project_details, totalDays, state.summary);
            else
              summaryService.updateFinishTimeDaysChanged(state.summary.warranty, state.project_details, totalDays, state.summary);
          }
        }
      }
    },
    updateTimeLineWarranty(state, total_development_number) {
      if(state.project_details.estimation_base === "0")
        summaryService.updateFinishTimeHoursChanged(state.summary.warranty, state.project_details, total_development_number, state.summary);
      else
        summaryService.updateFinishTimeDaysChanged(state.summary.warranty, state.project_details, total_development_number, state.summary);
    },
    updateScaleWarranty(state, total_development_number) {
      summaryService.updateScaleChanged(state.summary.warranty, state.project_details, total_development_number, state.summary);
    },
    updateChangeTime(state, total_development_number) {
      summaryService.updateChangeTime(state.summary.warranty, state.project_details, total_development_number, state.summary);
    },
    updateAfterChangeWorkingHours(state, settings, total_development_number) {
      summaryService.updateAfterChangeWorkingHours(state.summary.warranty, settings, total_development_number);
    },
    updateScaleSQA(state, total_development_number) {
      let scale = parseFloat(state.summary.project_managerment_sqa.sqa.scale) / 100;
      if(isNaN(scale))  {
        scale = 0.1;
        state.summary.project_managerment_sqa.sqa.scale = "10";
      }
      const days = scale * total_development_number;
      state.summary.project_managerment_sqa.sqa.days = days.toString();
      state.summary.project_managerment_sqa.sqa.hours = days*state.project_details.working_hours;
    },
    updateHoursDaysSQA(state, total_development_number) {
      let days = 0;
      if(state.project_details.estimation_base === "0") {
        const hours = parseFloat(state.summary.project_managerment_sqa.sqa.hours);
        days = hours / parseFloat(state.project_details.working_hours);
        state.summary.project_managerment_sqa.sqa.days = days.toString();
      } else {
        days = parseFloat(state.summary.project_managerment_sqa.sqa.days);
        state.summary.project_managerment_sqa.sqa.hours = (days * parseFloat(state.project_details.working_hours)).toString();
      }
      state.summary.project_managerment_sqa.sqa.scale = ((days / total_development_number) * 100 ).toString();
    },
    updateScaleProjectManagement(state, total_development_number) {
      let scale = parseFloat(state.summary.project_managerment_sqa.project_management.scale) / 100;
      if(isNaN(scale))  {
        scale = 0.1;
        state.summary.project_managerment_sqa.project_management.scale = "10";
      }
      const days = scale * total_development_number;
      state.summary.project_managerment_sqa.project_management.days = days.toString();
      state.summary.project_managerment_sqa.project_management.hours = days*state.project_details.working_hours;
    },
    updateHoursDaysProjectManagement(state, total_development_number) {
      let days = 0;
      if(state.project_details.estimation_base === "0") {
        const hours = parseFloat(state.summary.project_managerment_sqa.project_management.hours);
        days = hours / parseFloat(state.project_details.working_hours);
        state.summary.project_managerment_sqa.project_management.days = days.toString();
      } else {
        days = parseFloat(state.summary.project_managerment_sqa.project_management.days);
        state.summary.project_managerment_sqa.project_management.hours = (days * parseFloat(state.project_details.working_hours)).toString();
      }
      state.summary.project_managerment_sqa.project_management.scale = ((days / total_development_number) * 100 ).toString();
    },
    handleTimeStartPerPart(state, block) {
      const {rootState, arrayPart} = block;
      service.handleTimeStartPerPart(rootState, arrayPart);
    },
    importDataEstimation(state, block) {
      const { rootState, object } = block;
      rootState.work = JSON.parse(JSON.stringify(object.work));
      rootState.preparation = JSON.parse(JSON.stringify(object.preparation));
      rootState.development = JSON.parse(JSON.stringify(object.development));
      rootState.delivery = JSON.parse(JSON.stringify(object.delivery));
    },
    resetDateEstimation(state, block) {
      const { rootState, object } = block;
      rootState.work = JSON.parse(JSON.stringify(object.work));
      rootState.preparation = JSON.parse(JSON.stringify(object.preparation));
      rootState.development = JSON.parse(JSON.stringify(object.development));
      rootState.delivery = JSON.parse(JSON.stringify(object.delivery));
    }
  },
  actions: {
    updateFinishDaysDevelopment({ commit, rootState }, totalDays) {
      commit("updateFinishDaysDevelopment", { rootState: rootState, totalDays: totalDays });
    },
    updateTimeLineWarranty({ commit }, total_development_number) {
      commit("updateTimeLineWarranty", total_development_number);
    },
    updateScaleWarranty({ commit }, total_development_number) {
      commit("updateScaleWarranty", total_development_number);
    },
    updateChangeTime({ commit }, total_development_number) {
      commit("updateChangeTime", total_development_number);
    },
    updateAfterChangeWorkingHours({ commit, rootState }, total_development_number) {
      commit("updateAfterChangeWorkingHours", rootState.work.project_details, total_development_number);
    },
    updateScaleSQA({ commit }, total_development_number) {
      commit("updateScaleSQA", total_development_number);
    },
    updateHoursDaysSQA({ commit }, total_development_number) {
      commit("updateHoursDaysSQA", total_development_number);
    },
    updateScaleProjectManagement({ commit }, total_development_number) {
      commit("updateScaleProjectManagement", total_development_number);
    },
    updateHoursDaysProjectManagement({ commit }, total_development_number) {
      commit("updateHoursDaysProjectManagement", total_development_number);
    },
    handleTimeStartPerPart({ commit, rootState }, arrayPart) {
      commit("handleTimeStartPerPart", {rootState: rootState, arrayPart: arrayPart});
    },
    importDataEstimation({ commit, rootState }, object) {
      commit("importDataEstimation", {rootState: rootState, object: object});
    },
    resetDateEstimation({ commit, rootState }, object) {
      commit("resetDateEstimation", {rootState: rootState, object: object});
    }
  },
  getters: {},
};

export default Work;
