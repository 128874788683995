import { createStore } from "vuex";
import preparation from "../store/Modules/Preparation";
import work from "../store/Modules/Work";
import notification from "../store/Modules/Notification";
import development from "../store/Modules/Development";
import delivery from "../store/Modules/Delivery";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    preparation: preparation, 
    work: work, 
    notification: notification, 
    development: development,
    delivery: delivery,
  },
});
