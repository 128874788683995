<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <slot />
</template>

<script setup>
import $ from "jquery";
import "@/assets/js/plugin/jquery-ui-1.12.1.custom/jquery-ui.min"
import "@/assets/js/plugin/sweetalert/sweetalert.min"
import { defineProps, defineEmits } from "vue"
const props = defineProps({
  typeAlert: {
    type: String,
    required: false,
    default: "DeleteAlert"
  },
  title: {
    type: String,
    required: false,
    default: ""
  },
  message: {
    type: String,
    required: false,
    default: ""
  },
  textConfirmAlertDelete: {
    type: String,
    required: false,
    default: "",
  },
  titleNoticeSuccessDelete: {
    type: String,
    required: false,
    default: "",
  },
  textNoticeSuccessDelete: {
    type: String,
    required: false,
    default: "",
  },
  idAlert: {
    type: String,
    required: true,
  },
  autoShow: {
    type: Boolean,
    required: false,
    default: false,
  }
});
const classButton = {
  'warning':'btn btn-warning',
  'error':'btn btn-danger',
  'success':'btn btn-success',
  'info':'btn btn-info',
};
const emits = defineEmits(["handleConfirmDelete"]);
var SweetAlert2Demo = function() {
  var initDemos = function() {
    if(props.typeAlert === "DeleteAlert") {
      $("#"+props.idAlert).click((e) => {
        swal({
          title: props.title,
          text: props.message,
          icon: "warning",
          buttons: {
            confirm: {
              text: props.textConfirmAlertDelete,
              className: "btn btn-success",
            },
            cancel: {
              visible: true,
              className: "btn btn-danger",
            },
          },
        }).then((Delete) => {
          if (Delete) {
            swal({
              title: props.titleNoticeSuccessDelete,
              text: props.textNoticeSuccessDelete,
              icon: "success",
              buttons: {
                confirm: {
                  className: "btn btn-success",
                },
              },
            });
            emits("handleConfirmDelete");
          } else {
            swal.close();
          }
        });
      });
    } else {
      $('#'+props.idAlert).click(function(e) {
        swal(props.title, props.message, {
          icon : props.typeAlert,
          buttons: {        			
            confirm: {
              className : classButton[props.typeAlert],
            }
          },
        });
      });
    }
  };
  return {
    init: () => {
      initDemos();
    },
  };
}();
$(document).ready(function() {
  SweetAlert2Demo.init();
  if(props.autoShow) {
    $('#'+props.idAlert).click();
  }
});
</script>
