var cou = 7;
var dev = 7;
function mergeCell(sheet, columns) {
  try {
    sheet.unMergeCells(columns);
    sheet.mergeCells(columns);
  } catch (err) {
    console.log(err.message);
  }
}
function editHoursDays(object, settings) {
  let value = parseFloat(object.hours);
  if(settings.estimation_base === "1")
    value = parseFloat(object.days);
  if(Number.isNaN(value)) return "";
  if (Number.isInteger(value)) {
    return value;
  } else {
    return parseFloat(value.toFixed(2));
  }
}
function setValueCell(type, worksheet, incr, cell, value, alignment, size, bold, border, merge, background_color, text_color) {
  if(merge) {
    mergeCell(worksheet, cell);
  }
  worksheet.getCell(cell).value = value;
  worksheet.getCell(cell).value = value;
  if(alignment)
    worksheet.getCell(cell).alignment = { vertical: 'middle', horizontal: alignment, wrapText: true };
  worksheet.getCell(cell).font = {
    name: 'Tahoma', size: size, bold: bold,
  };
  if(text_color)  worksheet.getCell(cell).font.color = { argb: text_color };
  if(border){
    worksheet.getCell(cell).border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
  }
  if(background_color){
    worksheet.getCell(cell).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: background_color },
    };
  }
  if(type == 'sum')
    cou += incr;
  if(type == 'dev')
    dev += incr;
}
function setFrameSummary(summary, summary_details, language, settings) {
  summary.getColumn('B').width = 21;
  summary.getColumn('C').width = 21;
  summary.getColumn('D').width = 35;
  summary.getColumn('E').width = 18.5;
  const font_title = 14;
  const font_header = 10;
  const font_text = 9;
  const yellow = 'ffff00';
  const blue = '99ccff';
  const beige = 'ffff99';
  const gray = 'd9d9d9';
  const purple = '000090';

  for(const key of ['B5', 'C5', 'D5', 'E5']) {
    summary.getCell(key).border = { bottom: {style:'double',}, };
  }
  summary.getRow(cou).height = 32;
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, language("Summary").toUpperCase(), "center", font_title, true, '', true, '', purple);
  //Project Name
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, "1. "+language("ProjectName"), "left", font_header, true, '', true);
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, language("ProjectName")+": "+summary_details.project_name.project_name, "left", font_header, false, true, true);
  setValueCell('sum', summary, 0, `B${cou}`, language("Version"), "left", font_header, false, true, true);
  setValueCell('sum', summary, 0, `C${cou}`, summary_details.project_name.version, "left", font_header, false, true, true);
  setValueCell('sum', summary, 0, `D${cou}`, language("EstimationDate"), "left", font_header, false, true, true);
  setValueCell('sum', summary, 2, `E${cou}`, new Date(summary_details.project_name.estimation_date), "left", font_header, false, true, true);

  //Project Short Description
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, "2. "+language("ProjectShortDescription"), "left", font_header, true, '', true);
  summary.getRow(cou).height = 45;
  setValueCell('sum', summary, 2, `B${cou}:E${cou}`, summary_details.project_short_description, "left", font_header, false, true, true);

  //Assumptions and Constraints
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, "3. "+language("AssumptionsAndConstraints"), "left", font_header, true, '', true);
  summary.getRow(cou).height = 45;
  setValueCell('sum', summary, 2, `B${cou}:E${cou}`, summary_details.assumptions_and_constraints, "left", font_header, false, true, true);

  //Question
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, "4. "+language("QuestionFrom"), "left", font_header, true, '', true);
  summary.getRow(cou).height = 45;
  setValueCell('sum', summary, 2, `B${cou}:E${cou}`, summary_details.question, "left", font_header, false, true, true);

  //Quotation
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, "5. "+language("Quotation"), "left", font_header, true, '', true);
  summary.getRow(cou).height = 30;
  setValueCell('sum', summary, 0, `B${cou}`, language("TotalCost"), "left", font_header, true, true, true, yellow);
  setValueCell('sum', summary, 0, `C${cou}`, summary_details.quotation.currency, "right", font_header, true, true, true, yellow);
  setValueCell('sum', summary, 1, `D${cou}:E${cou}`, summary_details.quotation.total_cost, "center", font_header, true, true, true, yellow, purple);
  summary.getRow(cou).height = 30;
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`,language("ProjectCostStructure"), "left", font_header, true, true, true, blue);
  setValueCell('sum', summary, 0, `B${cou}:B${cou+3}`,language("DevelopmentCost"), "left", font_text, true, true, true, beige);

  //Development Cost
  let labels = [
    language("Rate"), 
    settings.estimation_base === '0' ? language("TotalInManHours") : language("TotalInManDays"),
    language("TotalInManMonths"), language("DevelopmentCost"),
  ];
  let values = [
    settings.estimation_base === '0' ? language("man-hour") : language("man-day"),
    settings.estimation_base === '0' ? summary_details.quotation.development_cost.total_in_man_hours : summary_details.quotation.development_cost.total_in_man_days,
    summary_details.quotation.development_cost.total_in_man_months,
    parseFloat(summary_details.quotation.development_cost.development_cost),
  ];
  for(const [index, value] of labels.entries()) {
    setValueCell('sum', summary, 0, `C${cou}`, value, "left", font_text, false, true, true, beige);
    setValueCell('sum', summary, 1, `D${cou}:E${cou}`, values[index], "center", font_text, false, true, true, gray);
  }
  labels = [
    language("SupportCost"), language("LiscenceCost"), language("OtherCost"),
  ];
  values = [
    parseFloat(summary_details.quotation.support_cost),
    parseFloat(summary_details.quotation.license_cost),
    parseFloat(summary_details.quotation.other_cost),
  ];
  for(const [index, value] of labels.entries()) {
    setValueCell('sum', summary, 0, `B${cou}`, value, "left", font_text, true, true, true, beige);
    setValueCell('sum', summary, 0, `C${cou}`, '', "left", font_text, false, true, true, beige);
    setValueCell('sum', summary, 1, `D${cou}:E${cou}`, values[index], "center", font_text, false, true, true, gray);
  }
  cou++;
  //Master Plan and Schedule
  setValueCell('sum', summary, 1, `B${cou}:E${cou}`, "6. "+language("MasterPlanAndSchedule"), "left", font_header, true, '', true);
  labels = [
    language("StartDate"), language("FinishDate"), language("FirstDemo"), language("UAT"),
  ];
  values = [
    new Date(summary_details.master_plan_and_schedule.start_date),
    new Date(summary_details.master_plan_and_schedule.finish_date),
    new Date(summary_details.master_plan_and_schedule.first_demo),
    new Date(summary_details.master_plan_and_schedule.UAT),
  ];
  for(let i = 0; i < 2; i++) {
    setValueCell('sum', summary, 0, `B${cou}`, labels[i*2], "left", font_header, false, true, true);
    setValueCell('sum', summary, 0, `C${cou}`, values[i*2], "right", font_header, false, true, true);
    setValueCell('sum', summary, 0, `D${cou}`, labels[i*2+1], "left", font_header, false, true, true);
    setValueCell('sum', summary, 1, `E${cou}`, values[i*2+1], "right", font_header, false, true, true);
  }
  for(const key of ['B'+cou, 'C'+cou, 'D'+cou, 'E'+cou]) {
    summary.getCell(key).border = { bottom: {style:'double',}, };
  }
}

function setFrameDevelopment(development, development_object, language, settings) {
  const { preparation, requirements_analysis, programming_and_testing, 
  delivery, preparation_details, requirements_analysis_details, 
  programming_and_testing_details, implementation_details, 
  development_details, delivery_details, summary, total_project_management } = development_object;
  development.getColumn('A').width = 2;
  development.getColumn('C').width = 43;
  development.getColumn('D').width = 18;
  development.getColumn('E').width = 18;
  development.getColumn('F').width = 11;
  development.getColumn('G').width = 9;
  development.getColumn('H').width = 6;
  development.getColumn('I').width = 6;
  development.getColumn('J').width = 7;
  development.getColumn('K').width = 8;
  development.getColumn('L').width = 13;
  const font_title = 14;
  const font_header = 12;
  const font_estimation = 11;
  const font_text = 8;

  const yellow = 'ffff00';
  const red = 'ff0000';
  const blue = '99ccff';
  const beige = 'ffff99';
  const gray = 'd9d9d9';
  const purple = '000090';
  const brown = '800000';
  const green = '92d050';

  for(const key of ['B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5', 'I5', 'J5', 'K5', 'L5']) {
    development.getCell(key).border = { bottom: {style:'double',}, };
  }

  //Title
  development.getRow(dev).height = 32;
  setValueCell('dev', development, 2, `B${dev}:F${dev}`, language("DevelopmentEffortEstimation").toUpperCase(), "center", font_title, true, '', true, '', purple);
  
  //Estimation Base On
  development.getRow(dev).height = 26.5;
  setValueCell('dev', development, 0, `B${dev}:D${dev}`, language("TheEstimationWillBasedOn")+":", "left", font_estimation, true, true, true, '', red);
  setValueCell('dev', development, 2, `E${dev}:F${dev}`, 
  settings.estimation_base === '0' ? language("man-hour") : language("man-day"), "right", font_estimation, true, true, true, '', red);
  
  //Preparation
  development.getRow(dev).height = 22;
  setValueCell('dev', development, 0, `B${dev}:F${dev}`, '1. '+language("Preparation"), "left", font_header, true, true, true, blue, '');
  development.getCell(`B${dev}:F${dev}`).font.underline = true;
  dev++;
  //Header
  let labels_values = {'B': '#', 'C': language("WorkItem"), 'D': language("StartDate"), 'E': language("FinishDate"), 'F': language("Total")};
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "center", font_text, true, true, true, blue, brown);
  }
  dev++;
  //Add Tasks to Preparation table
  for(const value of preparation) {
    labels_values = {'B': value.index, 'C': value.task_description, 'D': new Date(value.start_date), 
    'E': new Date(value.finish_date), 'F': parseFloat(value.total)};
    for(const key in labels_values) {
      if(key == 'B' || key == 'C')
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "left", font_text, false, true, true, '', '');
      else
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, false, true, true, '', '');
    }
    dev++;
  }
  //Footer
  labels_values = {'D': new Date(preparation_details.start_date), 'E': new Date(preparation_details.finish_date), 
  'F': editHoursDays(preparation_details, settings)};
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("Total"), "left", font_text, true, true, true, green, '');
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, true, true, true, green, '');
  }
  dev+=2;
  //Implementation
  development.getRow(dev).height = 22;
  setValueCell('dev', development, 0, `B${dev}:L${dev}`, '2. '+language("Implementation"), "left", font_header, true, true, true, blue, '');
  development.getCell(`B${dev}:L${dev}`).font.underline = true;
  dev++;
  //Header
  const columns_implementation = {'B': '#', 'C': language("WorkItem"), 'D': language("StartDate"), 'E': language("FinishDate"), 
  'K': language("Quantity"), 'L': language("Total")};
  const columns_effort_implementation = {'F': language("RequirementAnalysis") , 'G': language("SystemDesign"), 'J': language("Testing/BugsFixing")};
  for(const key in columns_implementation) {
    setValueCell('dev', development, 0, `${key}${dev}:${key}${dev+2}`, columns_implementation[key], "center", font_text, true, true, true, blue, brown);
  }
  setValueCell('dev', development, 1, `F${dev}:J${dev}`, language("Effort"), "center", font_text, true, true, true, blue, brown);
  development.getRow(dev+1).height = 36;
  for(const key in columns_effort_implementation) {
    setValueCell('dev', development, 0, `${key}${dev}:${key}${dev+1}`, columns_effort_implementation[key], "center", font_text, true, true, true, blue, brown);
    development.getCell(`${key}${dev}:${key}${dev+1}`).alignment.wrapText = true;
  }
  setValueCell('dev', development, 1, `H${dev}:I${dev}`, language("Coding"), "center", font_text, true, true, true, blue, brown);
  setValueCell('dev', development, 0, `H${dev}`, language("FE"), "center", font_text, true, true, true, blue, brown);
  setValueCell('dev', development, 1, `I${dev}`, language("BE"), "center", font_text, true, true, true, blue, brown);

  //Add tasks to Requirement Analysis
  setValueCell('dev', development, 1, `B${dev}:L${dev}`, language("RequirementAnalysis"), "left", font_text, true, true, true, beige, '');
  for(const value of requirements_analysis) {
    labels_values = {'B': value.index, 'C': value.task_description, 'D': new Date(value.start_date), 
    'E': new Date(value.finish_date), 'F': editHoursDays(value.effort.requirements_analysis, settings), 'K': parseFloat(value.quantity), 'L': parseFloat(value.total)};
    for(const key in labels_values) {
      if(key == 'B' || key == 'C')
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "left", font_text, false, true, true, '', '');
      else
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, false, true, true, '', '');
    }
    const label_hide = ['G', 'H', 'I', 'J'];
    for(const hide of label_hide) {
      setValueCell('dev', development, 0, `${hide}${dev}`, '', "right", font_text, false, true, true, gray, '');
    }
    dev++;
  }
  //Sub Total Requirement Analysis
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("SubTotal"), "left", font_text, true, true, true, yellow, '');
  labels_values = {'D': new Date(requirements_analysis_details.start_date), 
  'E': new Date(requirements_analysis_details.finish_date), 'F': editHoursDays(requirements_analysis_details, settings), 
  'L': editHoursDays(requirements_analysis_details, settings), 'G':'', 'H':'', 'I':'', 'J':'', 'K':''};
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, true, true, true, yellow, '');
  }
  dev++;
  //Add tasks to Programming and Testing
  setValueCell('dev', development, 1, `B${dev}:L${dev}`, language("ProgrammingAndTesting"), "left", font_text, true, true, true, beige, '');
  for(const value of programming_and_testing) {
    labels_values = {'B': value.index, 'C': value.task_description, 'D': new Date(value.start_date), 
    'E': new Date(value.finish_date), 'F': editHoursDays(value.effort.requirements_analysis, settings), 
    'G': editHoursDays(value.effort.system_design, settings), 'H': editHoursDays(value.effort.FE, settings), 
    'I': editHoursDays(value.effort.BE, settings), 'J': editHoursDays(value.effort.testing_bugs_fixing, settings), 
    'K': parseFloat(value.quantity), 'L': parseFloat(value.total)};
    for(const key in labels_values) {
      if(key == 'B' || key == 'C')
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "left", font_text, false, true, true, '', '');
      else
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, false, true, true, '', '');
    }
    dev++;
  }
  //Sub Total Programming and Testing
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("SubTotal"), "left", font_text, true, true, true, yellow, '');
  labels_values = {'D': new Date(programming_and_testing_details.start_date), 
  'E': new Date(programming_and_testing_details.finish_date), 'F': editHoursDays(programming_and_testing_details.requirements_analysis, settings), 
  'G': editHoursDays(programming_and_testing_details.system_design, settings), 
  'H': editHoursDays(programming_and_testing_details.FE, settings), 
  'I': editHoursDays(programming_and_testing_details.BE, settings), 
  'J': editHoursDays(programming_and_testing_details.testing_bugs_fixing, settings), 
  'K': '',
  'L': editHoursDays(programming_and_testing_details, settings),};
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, true, true, true, yellow, '');
  }
  dev++;
  //Total of Implementation
  labels_values = {'D': new Date(implementation_details.start_date), 'E': new Date(implementation_details.finish_date), 
  'L': editHoursDays(implementation_details, settings), 'F': '', 'G': '', 'H': '', 'I': '', 'J': '', 'K': ''};
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("Total"), "left", font_text, true, true, true, green, '');
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, true, true, true, green, '');
  }
  dev+=2;

  //Delivery
  development.getRow(dev).height = 22;
  setValueCell('dev', development, 0, `B${dev}:H${dev}`, '3. '+language("Delivery"), "left", font_header, true, true, true, blue, '');
  development.getCell(`B${dev}:F${dev}`).font.underline = true;
  dev++;
  //Header
  labels_values = {'B': '#', 'C': language("WorkItem"), 'D': language("StartDate"), 'E': language("FinishDate"), 'F': language("Total")};
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "center", font_text, true, true, true, blue, brown);
  }
  setValueCell('dev', development, 1, `G${dev}:H${dev}`, language("Note"), "center", font_text, true, true, true, blue, brown);
  //Add Tasks to Preparation table
  for(const value of delivery) {
    labels_values = {'B': value.index, 'C': value.task_description, 'D': new Date(value.start_date), 
    'E': new Date(value.finish_date), 'F': parseFloat(value.total)};
    for(const key in labels_values) {
      if(key == 'B' || key == 'C')
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "left", font_text, false, true, true, '', '');
      else
        setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, false, true, true, '', '');
    }
    setValueCell('dev', development, 0, `G${dev}:H${dev}`, value.note, "left", font_text, false, true, true, '', '');
    dev++;
  }
  //Footer
  labels_values = {'D': new Date(delivery_details.start_date), 'E': new Date(delivery_details.finish_date), 
  'F': editHoursDays(delivery_details, settings)};
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("Total"), "left", font_text, true, true, true, green, '');
  setValueCell('dev', development, 0, `G${dev}:H${dev}`, '', "left", font_text, true, true, true, green, '');
  for(const key in labels_values) {
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, true, true, true, green, '');
  }

  dev+=2;
  for(const key of ['B'+dev, 'C'+dev, 'D'+dev, 'E'+dev, 'F'+dev, 'G'+dev, 'H'+dev, 'I'+dev, 'J'+dev, 'K'+dev, 'L'+dev]) {
    development.getCell(key).border = { bottom: {style:'double',}, };
  }
  dev+=2;
  //Summary
  development.getRow(dev).height = 18;
  setValueCell('dev', development, 1, `B${dev}:G${dev}`, language("Summary"), "left", font_header, true, true, true, blue, '');
  //Header
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("Task"), "center", font_text, true, true, true, blue, brown);
  labels_values = {'D': language("StartDate"), 'E':  language("FinishDate"), 'F':  language("Scale"), 'G':  language("Total"), }
  for(const key in labels_values)
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "center", font_text, true, true, true, blue, brown);
  dev++;

  //Development
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("Development"), "left", font_text, true, true, true, beige, '');
  labels_values = {'D': new Date(summary.master_plan_and_schedule.start_date), 'E':  new Date(summary.master_plan_and_schedule.finish_date), 
  'F': '', 'G':  parseFloat(development_details.total), }
  for(const key in labels_values){
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, true, true, true, beige, '');
  }
  dev++;
  const details = {'Preparation': preparation_details, 'Implementation': implementation_details, 'Delivery': delivery_details};
  for(const key in details) {
    setValueCell('dev', development, 0, `B${dev}:C${dev}`, language(key), "left", font_text, false, true, true, '', '');
    labels_values = {'D': new Date(details[key].start_date), 'E': new Date(details[key].finish_date), 'F': '', 'G': editHoursDays(details[key], settings)};
    for(const key_ in labels_values){
      setValueCell('dev', development, 0, `${key_}${dev}`, labels_values[key_], "right", font_text, false, true, true, '', '');
    }
    dev++;
  }
  //Warranty
  setValueCell('dev', development, 0, `B${dev}:F${dev}`, language("Warranty", [summary.warranty.days]), "left", font_text, true, true, true, beige, '');
  setValueCell('dev', development, 1, `G${dev}`, editHoursDays(summary.warranty, settings), "right", font_text, true, true, true, beige, '');
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("FixLostBugsAndIssues"), "left", font_text, false, true, true, '', '');
  labels_values = {'D': new Date(summary.warranty.start_date), 'E': new Date(summary.warranty.finish_date), 
  'F': parseFloat(summary.warranty.scale).toFixed(2)+" %", 'G': editHoursDays(summary.warranty, settings)};
  for(const key in labels_values){
    setValueCell('dev', development, 0, `${key}${dev}`, labels_values[key], "right", font_text, false, true, true, '', '');
  }
  dev++;
  //Project Managerment and SQA
  setValueCell('dev', development, 0, `B${dev}:F${dev}`, language("ProgrammingAndTesting") +" & "+ language("SQA"), "left", font_text, true, true, true, beige, '');
  setValueCell('dev', development, 1, `G${dev}`, total_project_management, "right", font_text, true, true, true, beige, '');
  const project_management_sqa = {'ProjectManagement': summary.project_managerment_sqa.project_management, 'SQA': summary.project_managerment_sqa.sqa};
  for(const key in project_management_sqa) {
    setValueCell('dev', development, 0, `B${dev}:C${dev}`, key, "left", font_text, false, true, true, '', '');
    labels_values = {'D': '', 'E': '', 'F': parseFloat(project_management_sqa[key].scale).toFixed(2)+' %', 'G': editHoursDays(project_management_sqa[key], settings)};
    for(const key_ in labels_values) {
      setValueCell('dev', development, 0, `${key_}${dev}`, labels_values[key_], "right", font_text, false, true, true, '', '');
    }
    dev++;
  }
  //Footer
  setValueCell('dev', development, 0, `B${dev}:C${dev}`, language("TotalProjectEffort"), "left", font_estimation, true, true, true, green, '');
  setValueCell('dev', development, 0, `D${dev}`, new Date(summary.master_plan_and_schedule.start_date), "right", font_estimation, true, true, true, green, '');
  setValueCell('dev', development, 0, `E${dev}`, new Date(summary.warranty.finish_date), "right", font_estimation, true, true, true, green, '');
  setValueCell('dev', development, 0, `F${dev}`, '', "left", font_estimation, true, true, true, green, '');
  setValueCell('dev', development, 0, `G${dev}`, parseFloat(development_details.project_total).toFixed(2), "right", font_estimation, true, true, true, green, red);
}


function resetCount() {
  cou = 7;
  dev = 7;
}

module.exports = {
  mergeCell,
  editHoursDays,
  setFrameSummary,
  setFrameDevelopment,
  resetCount,
};