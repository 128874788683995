import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import "./assets/css/bootstrap.min.css"
import "./assets/css/atlantis.min.css"
import "./assets/css/fonts.min.css"
import "./assets/css/demo.css"
import VueDatePicker from '@vuepic/vue-datepicker';
import Modal from '@/components/Modal.vue';
import '@vuepic/vue-datepicker/dist/main.css'
import "./assets/css/global.css"

createApp(App).use(i18n).use(store).use(router).component("VueDatePicker", VueDatePicker).component("Modal", Modal).mount('#app');
