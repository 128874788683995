import { format } from 'date-fns';
import { timeService } from "@/services";
export const service = {
  addObjectByIndex,
  updateIDTask,
  deleteTask,
  updateTotalTimeDevelopment,
  handleTimeStartPerPart,
  convertObjectToGanttChart,
  pushTaskParent,
  convertTasksToArray,
  checkBeforeConvertToPDF,
  isJSON,
  haveSameKeys
};

// Thêm task/sub task mới
function addObjectByIndex(obj, targetIndex, newObject, rootIndex) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (obj[key].index === targetIndex) {
        const current_key = Object.keys(obj[key].sub_task).length + 1;
        newObject.index = obj[key].index + "." + current_key;
        obj[key].sub_task[current_key] = newObject;
        return true;
      } else if (obj[key].sub_task && addObjectByIndex(obj[key].sub_task, targetIndex, newObject, rootIndex)) {
        return true;
      }
    }
  }
  return false;
}

//Xóa task/sub task
function deleteTask(object, targetIndex) {
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      if (object[key].index === targetIndex) {
        delete object[key];
        return true;
      } else if (object[key].sub_task && Object.keys(object[key].sub_task).length > 0 && deleteTask(object[key].sub_task, targetIndex)) {
        return true;
      }
    }
  }
  return false;
}

//Cập nhật lại đầu mục sau khi xóa task/sub task
function updateIDTask(object, new_object, parentIndex) {
  let index = 1;
  const keysArray = Object.keys(object);
  keysArray.sort();
  for (const key of keysArray) {
    new_object[index] = JSON.parse(JSON.stringify(object[key]));
    new_object[index].index = parentIndex + "." + index;
    new_object[index].created_at = Math.random().toString(30);
    new_object[index].sub_task = JSON.parse(JSON.stringify({}));
    if (object[key].sub_task && Object.keys(object[key].sub_task).length > 0) {
      updateIDTask(object[key].sub_task, new_object[index].sub_task, new_object[index].index);
    }
    index++;
  }
}

function updateTotalTimeDevelopment(object) {
  if(Object.keys(object.requirements_analysis.task).length > 0 || Object.keys(object.programming_and_testing.task).length > 0) {
    const start_time_ra = object.requirements_analysis.start_date ? 
    new Date(object.requirements_analysis.start_date) : new Date(8640000000000000);
    const finish_time_ra = object.requirements_analysis.finish_date ? 
    new Date(object.requirements_analysis.finish_date) : new Date(-8640000000000000);
    const hours_ra = object.requirements_analysis.hours ? 
    parseFloat(object.requirements_analysis.hours) : 0;
    const dayss_ra = object.requirements_analysis.days ? 
    parseFloat(object.requirements_analysis.days) : 0;

    const start_time_pat = object.programming_and_testing.start_date ? 
    new Date(object.programming_and_testing.start_date) : new Date(8640000000000000);
    const finish_time_pat = object.programming_and_testing.finish_date ? 
    new Date(object.programming_and_testing.finish_date) : new Date(-8640000000000000);
    const hours_pat = object.programming_and_testing.hours ? 
    parseFloat(object.programming_and_testing.hours) : 0;
    const dayss_pat = object.programming_and_testing.days ? 
    parseFloat(object.programming_and_testing.days) : 0;

    object.start_date = start_time_pat > start_time_ra ? format(start_time_ra, "yyyy-MM-dd") : 
    format(start_time_pat, "yyyy-MM-dd");

    object.finish_date = finish_time_pat > finish_time_ra ? format(finish_time_pat, "yyyy-MM-dd") : 
    format(finish_time_ra, "yyyy-MM-dd");

    object.hours = (hours_ra+hours_pat).toString();
    object.days = (dayss_ra+dayss_pat).toString();
  } else {
    object.start_date = "";
    object.finish_date = "";
    object.hours = "";
    object.days = "";
  }
}

function handleTimeStartPerPart(rootState, arrayPart) {
  if(Object.keys(rootState[arrayPart[0]][arrayPart[1]].task).length === 0) {
    resetStartFinishTime(rootState[arrayPart[0]][arrayPart[1]]);
  }
}

//convert object to object gantt chart
function convertObjectToGanttChart(obj, new_obj, parent, settings, color, textColor, setFinishHours) {
  if(Object.keys(obj).length > 0) {
    for(var key in obj) {
      const start_date = new Date(obj[key].start_date);
      start_date.setHours(0, 0, 0, 0);
      const object = {
        id: obj[key].index,
        text: obj[key].index + '.' +obj[key].task_description,
        duration: settings.estimation_base === "0" ? Math.ceil(obj[key].hours) : obj[key].days,
        parent: parent,
        color: color,
        textColor: textColor,
        open: true,
        created_at: obj[key].created_at,
        total_time: settings.estimation_base === "0" ? obj[key].hours : obj[key].days,
        sub_task: "",
      };
      if(settings.estimation_base === "0") {
        start_date.setHours(8, 0, 0, 0);
        object.start_date = start_date;
      } else {
        start_date.setHours(0, 0, 0, 0);
        object.start_date = start_date;
      }
      if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
        convertObjectToGanttChart(obj[key].sub_task, new_obj, obj[key].index, settings, "", "", setFinishHours);
        if(settings.estimation_base === "0") {
          const extra_hours = getBiggestFinishDate(obj[key].sub_task, settings);
          const finish_date = new Date(obj[key].finish_date);
          finish_date.setHours(setFinishHours[0], setFinishHours[1], setFinishHours[2], setFinishHours[3]);
          let parent_days = timeService.calculateDaysBetween(start_date, finish_date, settings);
          let parent_hours = parent_days * parseFloat(settings.working_hours);
          if (extra_hours !== 0) {
            parent_hours = (parent_days - 1) * parseFloat(settings.working_hours) + extra_hours; 
          }
          object.duration = settings.estimation_base === "0" ? parent_hours : parent_days;
        }
        object.sub_task = true;
      }
      new_obj.push(object);
    }
  }
}

function getBiggestFinishDate (obj, settings) {
  let biggestFinishDate = { finish_date: new Date(-8640000000000000), hours: 0 };
  for(var key in obj) {
    if(biggestFinishDate.finish_date < new Date(obj[key].finish_date) || biggestFinishDate.finish_date == new Date(obj[key].finish_date) && biggestFinishDate.hours < parseFloat(obj[key].hours)) {
      biggestFinishDate.finish_date = new Date(obj[key].finish_date);
      biggestFinishDate.hours = parseFloat(obj[key].hours);
    }
  }
  if(biggestFinishDate.date != new Date(-8640000000000000)) {
    const days = parseInt(biggestFinishDate.hours / parseFloat(settings.working_hours));
    return biggestFinishDate.hours - days*parseFloat(settings.working_hours);
  }
  else return "";
}

//convert object to object gantt chart
function pushTaskParent(root, obj, new_obj, parent, settings, color, textColor) {
  let format_date = { finish_date: new Date(-8640000000000000), hours: 0 };
  getBiggestFinishDateLeafNode(root, format_date);
  let hours = Math.ceil(obj.hours);
  const days = parseInt(format_date.hours / parseFloat(settings.working_hours));
  const extra_hours = format_date.hours - days*parseFloat(settings.working_hours);
  if (extra_hours !== 0) {
    hours = hours - parseFloat(settings.working_hours) + extra_hours;
  }
  const start_date = new Date(obj.start_date);
  if(settings.estimation_base === "0")
    start_date.setHours(8, 0, 0, 0);
  else
    start_date.setHours(0, 0, 0, 0);
  new_obj.push({
    id: obj.index,
    text: obj.index + '. ' +obj.task_description,
    start_date: start_date,
    duration: settings.estimation_base === "0" ? hours : obj.days,
    parent: parent,
    color: color,
    textColor: textColor,
    open: true,
    created_at: obj.created_at,
    total_time: settings.estimation_base === "0" ? obj.total_hours : obj.total_days,
  });
}

function getBiggestFinishDateLeafNode(obj, format_date) {
  for(var key in obj) {
    if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
      getBiggestFinishDateLeafNode(obj[key].sub_task, format_date);
    } else {
      if(format_date.finish_date < new Date(obj[key].finish_date) || format_date.finish_date == new Date(obj[key].finish_date) && format_date.hours < parseFloat(obj[key].hours)) {
        format_date.finish_date = new Date(obj[key].finish_date);
        format_date.hours = parseFloat(obj[key].hours);
      }
    }
  }
}

//Get All Tasks of Part
//convert object to object gantt chart
function convertTasksToArray(obj, new_obj, settings, type, format_date) {
  if(Object.keys(obj).length > 0) {
    for(var key in obj) {
      let start_date = new Date(obj[key].start_date);
      let finish_date = new Date(obj[key].finish_date);
      if(isNaN(start_date)) start_date = '';
      else {
        start_date = format(new Date(obj[key].start_date), format_date);
      }
      if(isNaN(finish_date)) finish_date = '';
      else {
        finish_date = format(new Date(obj[key].finish_date), format_date);
      }
      const object = {
        index: obj[key].index,
        task_description: obj[key].task_description,
        start_date: start_date,
        finish_date: finish_date,
        total: settings.estimation_base === "0" ? obj[key].hours : obj[key].days,
      };
      if(type === 'dev') {
        object.effort = obj[key].effort;
        object.quantity = obj[key].quantity;
      }
      if(type === 'del') {
        object.note = obj[key].note;
      }
      new_obj.push(object);
      if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
        convertTasksToArray(obj[key].sub_task, new_obj, settings, type, format_date);
      }
    }
  }
}

function checkBeforeConvertToPDF(obj) {
  const { preparation, requirements_analysis, programming_and_testing, delivery, summary } = obj;
  for(const key in summary) {
    if(!summary[key]) return false;
  }
  for(const key in summary.project_name) {
    if(!summary.project_name[key]) return false;
  }
  for(const key in summary.quotation) {
    if(!summary.quotation[key]) return false;
  }
  for(const key in summary.quotation.development_cost) {
    if(!summary.quotation.development_cost[key]) return false;
  }
  for(const key in summary.warranty) {
    if(!summary.warranty[key]) return false;
  }
  for(const key in summary.project_managerment_sqa.project_management) {
    if(!summary.project_managerment_sqa.project_management[key]) return false;
  }
  for(const key in summary.project_managerment_sqa.sqa) {
    if(!summary.project_managerment_sqa.sqa[key]) return false;
  }
  for(const key in summary.master_plan_and_schedule) {
    if(!summary.master_plan_and_schedule[key]) return false;
  }
  if(Object.keys(preparation.task).length === 0)  return false;
  if(Object.keys(requirements_analysis.task).length === 0)  return false;
  if(Object.keys(programming_and_testing.task).length === 0)  return false;
  if(Object.keys(delivery.task).length === 0)  return false;
  return true;
}

function isJSON(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

function haveSameKeys(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }
  }
  return true;
}

function resetStartFinishTime(object) {
  object.start_date = "";
  object.finish_date = "";
}