<template>
  <div class="custom-template" ref="ganttChart">
    <div class="title d-flex justify-content-between d-flex align-items-center">
      <span>{{ $t("Chart") }} <i 
        @click="handleShowGrid" 
        class="fa ms-2 rounded-circle cursor-pointer bg-white text-primary p-2"
        :class="{'fa-eye': isShowGrid, 'fa-eye-slash': !isShowGrid}"
      ></i><i class="fa-solid fa-file-pdf ms-2 rounded-circle cursor-pointer bg-white text-primary p-2" @click="handleExportPDF"></i></span>
      <i class="fa-solid fa-xmark fa-xl cursor-pointer"  @click="handleToggleGanttChart"></i>
    </div>
    <div class="custom-content">
      <div class="switcher">
        <div class="container-max">
          <GanttComponent ref="gantt_chart" v-if="this.isShow" class="left-container" :tasks="totalTask"></GanttComponent>
        </div>
      </div>
    </div>
    <div class="custom-toggle" @click="handleToggleGanttChart">
      <i class="flaticon-settings"></i>
    </div>
  </div>
</template>

<script setup>
import "@/assets/js/setting-demo.js";
</script>

<script>
import GanttComponent from '@/components/GanttComponent.vue';
import { service, timeService } from "@/services";
import { mapState } from "vuex";
export default {
  data () {
    return {
      isShow: false,
      isShowGrid: true,
      tasks: {
        data: [
          {id: 1, text: 'Task #1', start_date: new Date('2023-08-28 08:00'), duration: 4, open: true, parent: ""},
          {id: 2, text: 'Task #2', start_date: new Date('2023-08-30 8:00'), end_date: new Date('2023-09-30 12:00'), open: true, parent: "1"}
        ],
      },
      work_hours: {
        4: [12, 0, 0, 0],
        4.5: [13, 45, 0, 0],
        5: [14, 15, 0, 0],
        5.5: [14, 45, 0, 0],
        6: [15, 15, 0, 0],
        6.5: [15, 45, 0, 0],
        7: [16, 15, 0, 0],
        7.5: [16, 45, 0, 0],
        8: [17, 15, 0, 0],
        8.5: [17, 45, 0, 0],
        9: [18, 15, 0, 0],
        9.5: [18, 45, 0, 0],
        10: [19, 15, 0, 0],
      },
    };
  },
  computed: {
    ...mapState("work", ["project_details"]),
    totalTask() {
      const data = [];
      let root ="";
      if (this.$store.state.work.summary.warranty.finish_date) {
        const start_date = new Date(this.$store.state.work.summary.master_plan_and_schedule.start_date);
        start_date.setHours(8, 0, 0, 0);
        if(this.project_details.estimation_base === "1")  start_date.setHours(0, 0, 0, 0);
        const total_days = timeService.calculateDaysBetween(new Date(this.$store.state.work.summary.master_plan_and_schedule.start_date), new Date(this.$store.state.work.summary.warranty.finish_date), this.project_details);
        const total_hours = total_days * parseFloat(this.project_details.working_hours);
        data.push({
          id: "root",
          start_date: start_date,
          text: this.$store.state.work.summary.project_name.project_name,
          duration: this.project_details.estimation_base === "0" ? total_hours : total_days,
          parent: "",
          color: "#009999",
          open: true,
          textColor: "white",
          created_at: Math.random().toString(30),
          total_time: this.project_details.estimation_base === "0" ? Math.ceil(this.total_hours) : Math.ceil(this.total_days),
        });
        root = "root";
      }
      const list_part = [
        {object: this.$store.state.preparation.preparation, index: "1", task_description: this.$t("Preparation"), bg_parent: "#336666", text_parent: "white", bg_leaf: "#003399", text_leaf: "white"},
        {object: this.$store.state.development.requirements_analysis, index: "2I", task_description: this.$t("RequirementAnalysis"), bg_parent: "#336666", text_parent: "white", bg_leaf: "#33FFCC", text_leaf: "black"},
        {object: this.$store.state.development.programming_and_testing, index: "2II", task_description: this.$t("ProgrammingAndTesting"), bg_parent: "#336666", text_parent: "white", bg_leaf: "#A7D991", text_leaf: "black"},
        {object: this.$store.state.delivery.delivery, index: "3", task_description: this.$t("Delivery"), bg_parent: "#336666", text_parent: "white", bg_leaf: "#FFCC33", text_leaf: "black"}
      ];
      for(var item of list_part) {
        if(Object.keys(item.object.task).length > 0) {
          const preparation_days = timeService.calculateDaysBetween(new Date(item.object.start_date), new Date(item.object.finish_date), this.project_details);
          const preparation_hours = preparation_days * parseFloat(this.project_details.working_hours);
          service.pushTaskParent(item.object.task, {
            start_date: item.object.start_date,
            index: item.index,
            task_description: item.task_description,
            hours: preparation_hours,
            days: preparation_days,
            created_at: Math.random().toString(30),
            total_hours: item.object.hours,
            total_days: item.object.days,
          }, data, root, this.project_details, item.bg_parent, item.text_parent);
          service.convertObjectToGanttChart(item.object.task, data, item.index, this.project_details, item.bg_leaf, item.text_leaf, this.work_hours[this.project_details.working_hours]);
        }
      }
      if (this.$store.state.work.summary.warranty.finish_date) {
        const start_date = new Date(this.$store.state.work.summary.warranty.start_date);
        start_date.setHours(8, 0, 0, 0);
        const warranty = this.$store.state.work.summary.warranty;
        data.push({
          id: "4",
          start_date: start_date,
          text: this.$t("Warranty", [warranty.days]),
          duration: this.project_details.estimation_base === "0" ? Math.ceil(warranty.hours) : Math.ceil(warranty.days),
          parent: root,
          color: "#009999",
          open: true,
          textColor: "white",
          created_at: Math.random().toString(30),
          total_time: this.project_details.estimation_base === "0" ? Math.ceil(warranty.hours) : Math.ceil(warranty.days),
        });
      }
      const tasks = {
        data: data,
      }
      return tasks;
    },
    total_hours() {
      const preparation_hours = parseFloat(this.$store.state.preparation.preparation.hours);
      const development_hours = parseFloat(this.$store.state.development.hours);
      const delivery_hours = parseFloat(this.$store.state.delivery.delivery.hours);
      const warranty_hours = parseFloat(this.$store.state.work.summary.warranty.hours);
      const project_management_hours = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.project_management.hours);
      const sqa_hours = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.sqa.hours);
      const totalHours = (isNaN(preparation_hours) ? 0 : preparation_hours) + 
      (isNaN(development_hours) ? 0 : development_hours) +
      (isNaN(delivery_hours) ? 0 : delivery_hours) + (isNaN(warranty_hours) ? 0 : warranty_hours)
      + (isNaN(project_management_hours) ? 0 : project_management_hours)
      + (isNaN(sqa_hours) ? 0 : sqa_hours);
      return totalHours.toString();
    },
    total_days() {
      const preparation_days = parseFloat(this.$store.state.preparation.preparation.days);
      const development_days = parseFloat(this.$store.state.development.days);
      const delivery_days = parseFloat(this.$store.state.delivery.delivery.days);
      const warranty_days = parseFloat(this.$store.state.work.summary.warranty.days);
      const project_management_days = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.project_management.days);
      const sqa_days = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.sqa.days);
      const totalDays = (isNaN(preparation_days) ? 0 : preparation_days) + 
      (isNaN(development_days) ? 0 : development_days) +
      (isNaN(delivery_days) ? 0 : delivery_days) + (isNaN(warranty_days) ? 0 : warranty_days)
      + (isNaN(project_management_days) ? 0 : project_management_days)
      + (isNaN(sqa_days) ? 0 : sqa_days);
      return totalDays.toString();
    },
    total_preparation() {
      let value = parseFloat(this.$store.state.preparation.preparation.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.$store.state.preparation.preparation.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_implementation() {
      let value = parseFloat(this.$store.state.development.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.$store.state.development.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_delivery() {
      let value = parseFloat(this.$store.state.delivery.delivery.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.$store.state.delivery.delivery.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
  },
  components: {
    GanttComponent
  },
  methods: {
    handleToggleGanttChart() {
      if(this.isShow == true) {
        this.isShowGrid = true;
        this.$refs.gantt_chart.handleToggleGanttChart(true);
        this.$refs.gantt_chart.handleResetGanttChart();
      }
      const custom_template = this.$refs.ganttChart;
      const main_panel = this.$parent.$refs.mainPanel;
      custom_template.classList.toggle('show');
      main_panel.classList.toggle('disabled-overflow');
      this.isShow = !this.isShow;
    },
    handleShowGrid() {
      this.isShowGrid = !this.isShowGrid;
      this.$refs.gantt_chart.handleToggleGanttChart(this.isShowGrid);
    },
    handleExportPDF() {
      this.$refs.gantt_chart.handleExportPDF();
    }
  },
}
</script>

<style scoped>
.show {
  right: 0;
  overflow-x: auto;
  overflow-y: auto;
}
.container-max {
  height: 87vh;
  width: 100vw;
}
.left-container {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.custom-template .custom-content {
  padding: 0;
}
.custom-template .switcher {
  padding: 0;
}
</style>

