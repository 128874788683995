export default {
  "Summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tóm tắt"])};fn.source="Tóm tắt";return fn;})(),
  "Language": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngôn ngữ"])};fn.source="Ngôn ngữ";return fn;})(),
  "Chart": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu đồ hiển thị quá trình làm việc"])};fn.source="Biểu đồ hiển thị quá trình làm việc";return fn;})(),
  "ProjectName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên dự án"])};fn.source="Tên dự án";return fn;})(),
  "Version": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên bản"])};fn.source="Phiên bản";return fn;})(),
  "EstimationDate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày thiết lập"])};fn.source="Ngày thiết lập";return fn;})(),
  "ProjectShortDescription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mô tả ngắn về dự án"])};fn.source="Mô tả ngắn về dự án";return fn;})(),
  "AssumptionsAndConstraints": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mặc định và ràng buộc"])};fn.source="Mặc định và ràng buộc";return fn;})(),
  "QuestionFrom": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Câu hỏi từ ", _interpolate(_list(0))])};fn.source="Câu hỏi từ {0}";return fn;})(),
  "Quotation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trích dẫn"])};fn.source="Trích dẫn";return fn;})(),
  "TotalCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng chi phí"])};fn.source="Tổng chi phí";return fn;})(),
  "ProjectCostStructure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cơ cấu chi phí dự án"])};fn.source="Cơ cấu chi phí dự án";return fn;})(),
  "DevelopmentCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi phí phát triển"])};fn.source="Chi phí phát triển";return fn;})(),
  "SupportCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi phí hỗ trợ"])};fn.source="Chi phí hỗ trợ";return fn;})(),
  "LiscenceCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi phí giấy phép"])};fn.source="Chi phí giấy phép";return fn;})(),
  "OtherCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi phí khác"])};fn.source="Chi phí khác";return fn;})(),
  "Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỉ lệ"])};fn.source="Tỉ lệ";return fn;})(),
  "TotalInManDays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số ngày làm việc"])};fn.source="Tổng số ngày làm việc";return fn;})(),
  "TotalInManHours": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số giờ làm việc"])};fn.source="Tổng số giờ làm việc";return fn;})(),
  "TotalInManMonths": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số tháng làm việc"])};fn.source="Tổng số tháng làm việc";return fn;})(),
  "MasterPlanAndSchedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kế hoạch tổng thể và lịch trình"])};fn.source="Kế hoạch tổng thể và lịch trình";return fn;})(),
  "StartDate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày bắt đầu"])};fn.source="Ngày bắt đầu";return fn;})(),
  "FirstDemo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bản Demo đầu tiên"])};fn.source="Bản Demo đầu tiên";return fn;})(),
  "FinishDate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày hoàn thành"])};fn.source="Ngày hoàn thành";return fn;})(),
  "UAT": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày kiểm thử chấp nhận người dùng"])};fn.source="Ngày kiểm thử chấp nhận người dùng";return fn;})(),
  "DevelopmentEffortEstimation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ước tính nỗ lực phát triển"])};fn.source="Ước tính nỗ lực phát triển";return fn;})(),
  "TheEstimationWillBasedOn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ước tính sẽ dựa trên"])};fn.source="Ước tính sẽ dựa trên";return fn;})(),
  "man-hour": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo giờ"])};fn.source="Theo giờ";return fn;})(),
  "man-day": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theo ngày"])};fn.source="Theo ngày";return fn;})(),
  "Weekend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ bảy và Chủ nhật"])};fn.source="Thứ bảy và Chủ nhật";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủ nhật"])};fn.source="Chủ nhật";return fn;})(),
  "Day": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])};fn.source="Ngày";return fn;})(),
  "Month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tháng"])};fn.source="Tháng";return fn;})(),
  "Year": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Năm"])};fn.source="Năm";return fn;})(),
  "WeeklyDayOff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày nghỉ hàng tuần"])};fn.source="Ngày nghỉ hàng tuần";return fn;})(),
  "DateFormat": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng ngày"])};fn.source="Định dạng ngày";return fn;})(),
  "Preparation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuẩn bị"])};fn.source="Chuẩn bị";return fn;})(),
  "WorkItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục công việc"])};fn.source="Mục công việc";return fn;})(),
  "Total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng cộng"])};fn.source="Tổng cộng";return fn;})(),
  "AddTask": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm công việc"])};fn.source="Thêm công việc";return fn;})(),
  "AddSubTask": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm công việc con"])};fn.source="Thêm công việc con";return fn;})(),
  "RemoveTask": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa công việc này"])};fn.source="Xóa công việc này";return fn;})(),
  "WorkingHours": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số giờ làm việc"])};fn.source="Số giờ làm việc";return fn;})(),
  "Hours": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giờ"])};fn.source="Giờ";return fn;})(),
  "Implementation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thực hiện"])};fn.source="Thực hiện";return fn;})(),
  "Effort": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cố gắng"])};fn.source="Cố gắng";return fn;})(),
  "RequirementAnalysis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phân tích yêu cầu"])};fn.source="Phân tích yêu cầu";return fn;})(),
  "SystemDesign": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết kế hệ thống"])};fn.source="Thiết kế hệ thống";return fn;})(),
  "Coding": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coding"])};fn.source="Coding";return fn;})(),
  "FE": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giao diện"])};fn.source="Giao diện";return fn;})(),
  "BE": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chức năng"])};fn.source="Chức năng";return fn;})(),
  "Testing/BugsFixing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểm thử / Sửa lỗi"])};fn.source="Kiểm thử / Sửa lỗi";return fn;})(),
  "Quantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng"])};fn.source="Số lượng";return fn;})(),
  "SubTotal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng giờ của các công việc con"])};fn.source="Tổng giờ của các công việc con";return fn;})(),
  "Delivery": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển giao"])};fn.source="Chuyển giao";return fn;})(),
  "Task": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiệm vụ"])};fn.source="Nhiệm vụ";return fn;})(),
  "Scale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỉ lệ"])};fn.source="Tỉ lệ";return fn;})(),
  "Development": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phát triển"])};fn.source="Phát triển";return fn;})(),
  "Warranty": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bảo hành (", _interpolate(_list(0)), " ngày kể từ ngày kiểm thử chấp nhận người dùng)"])};fn.source="Bảo hành ({0} ngày kể từ ngày kiểm thử chấp nhận người dùng)";return fn;})(),
  "FixLostBugsAndIssues": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sửa lỗi và hỗ trợ sự cố"])};fn.source="Sửa lỗi và hỗ trợ sự cố";return fn;})(),
  "ProjectManagement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý dự án"])};fn.source="Quản lý dự án";return fn;})(),
  "ProgrammingAndTesting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lập trình và kiểm thử"])};fn.source="Lập trình và kiểm thử";return fn;})(),
  "SQA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giám sát, quản lý, đảm bảo chất lượng phần mềm"])};fn.source="Giám sát, quản lý, đảm bảo chất lượng phần mềm";return fn;})(),
  "TotalProjectEffort": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng nỗ lực của dự án"])};fn.source="Tổng nỗ lực của dự án";return fn;})(),
  "Note": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi chú"])};fn.source="Ghi chú";return fn;})(),
  "titleAlertDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bạn có chắc chắn muốn xóa công việc ", _interpolate(_list(0)), "?"])};fn.source="Bạn có chắc chắn muốn xóa công việc {0}?";return fn;})(),
  "messageAlertDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ không thể hoàn tác hành động này!"])};fn.source="Bạn sẽ không thể hoàn tác hành động này!";return fn;})(),
  "textConfirmAlertDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có, xóa nó đi!"])};fn.source="Có, xóa nó đi!";return fn;})(),
  "titleNoticeSuccessDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xóa!"])};fn.source="Đã xóa!";return fn;})(),
  "textNoticeSuccessDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Công việc ", _interpolate(_list(0)), " đã được xóa.!"])};fn.source="Công việc {0} đã được xóa.!";return fn;})(),
  "titleUpdateInformationCompany": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật thông tin công ty"])};fn.source="Cập nhật thông tin công ty";return fn;})(),
  "titleImportFile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tệp dữ liệu (JSON)"])};fn.source="Nhập tệp dữ liệu (JSON)";return fn;})(),
  "TaskName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên công việc"])};fn.source="Tên công việc";return fn;})(),
  "TotalTime": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng thời gian"])};fn.source="Tổng thời gian";return fn;})(),
  "ConfirmStoreJson": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có muốn tải file lưu trữ dữ liệu dự án hiện tại để có thể chỉnh sửa lại về sau không?"])};fn.source="Bạn có muốn tải file lưu trữ dữ liệu dự án hiện tại để có thể chỉnh sửa lại về sau không?";return fn;})(),
  "SaveChanges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập Nhật"])};fn.source="Cập Nhật";return fn;})(),
  "TitleErrorFileJson": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File JSON không đúng định dạng!"])};fn.source="File JSON không đúng định dạng!";return fn;})(),
  "ErrorFileJson": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tệp nhập vào không đúng định dạng hoặc chuẩn dữ liệu"])};fn.source="Tệp nhập vào không đúng định dạng hoặc chuẩn dữ liệu";return fn;})(),
  "TitleErrorExport": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Lỗi khi trích xuất file ", _interpolate(_list(0)), "!"])};fn.source="Lỗi khi trích xuất file {0}!";return fn;})(),
  "MessageErrorExport": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Không thể trích xuất file ", _interpolate(_list(0)), " vì gặp phải lỗi. Hãy thử lại!"])};fn.source="Không thể trích xuất file {0} vì gặp phải lỗi. Hãy thử lại!";return fn;})(),
  "Success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thành công!"])};fn.source="Thành công!";return fn;})(),
  "ImportSuccessFile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tệp dữ liệu vào hệ thống thành công!"])};fn.source="Nhập tệp dữ liệu vào hệ thống thành công!";return fn;})(),
  "SuccessDeleteFile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa dữ liệu thành công!"])};fn.source="Xóa dữ liệu thành công!";return fn;})(),
  "SuccessImportFile": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Chuyển thành công dữ liệu sang kiểu ", _interpolate(_list(0)), " và đã tải xuống"])};fn.source="Chuyển thành công dữ liệu sang kiểu {0} và đã tải xuống";return fn;})(),
  "NoticeUpdateCompanySuccessful": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật thông tin công ty thành công!"])};fn.source="Cập nhật thông tin công ty thành công!";return fn;})(),
  "NoticeUpdateCompanyFailed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cập nhật thông tin công ty thất bại!"])};fn.source="Cập nhật thông tin công ty thất bại!";return fn;})(),
  "CompanyName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên công ty"])};fn.source="Tên công ty";return fn;})(),
  "CompanyAddress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ công ty"])};fn.source="Địa chỉ công ty";return fn;})(),
  "CompanyPhone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại công ty"])};fn.source="Số điện thoại công ty";return fn;})(),
  "CompanyEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email công ty"])};fn.source="Email công ty";return fn;})(),
  "TheHourIsVacant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giờ hoàn thành không được trống!"])};fn.source="Giờ hoàn thành không được trống!";return fn;})(),
  "TheHourIsVacantMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Giờ làm hoàn thành công việc của mục ", _interpolate(_list(0)), " vừa rồi bạn đã để trống nên được cài lại thành giờ làm việc trước đó!"])};fn.source="Giờ làm hoàn thành công việc của mục {0} vừa rồi bạn đã để trống nên được cài lại thành giờ làm việc trước đó!";return fn;})(),
  "TheDayIsVacant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày hoàn thành không được trống!"])};fn.source="Ngày hoàn thành không được trống!";return fn;})(),
  "TheDayIsVacantMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ngày làm hoàn thành công việc của mục ", _interpolate(_list(0)), " vừa rồi bạn đã để trống nên được cài lại thành ngày làm việc trước đó!"])};fn.source="Ngày làm hoàn thành công việc của mục {0} vừa rồi bạn đã để trống nên được cài lại thành ngày làm việc trước đó!";return fn;})(),
  "ResetData": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc chắn muốn cài lại toàn bộ dữ liệu?"])};fn.source="Bạn có chắc chắn muốn cài lại toàn bộ dữ liệu?";return fn;})()
}