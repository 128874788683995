<template>
  <div>
    <img :src="image" alt="" />
  </div>
</template>

<script>
export default {
  setup() {
  },
  computed: {
    image() {
      return require('../assets/img/logo/logo.png');
    }
  },
}
</script>

<style scoped>
img {
  height: 30px;
  width: 100%;
}
</style>
