import $ from "jquery";
import "@/assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js"
const Notification = {
  namespaced: true,
  state: {
    from: {
      "top": "top",
      "bottom": "nottom"
    },
    align: {
      "left": "left",
      "right": "right",
      "center": "center"
    },
    state: {
      "default": "default",
      "primary": "primary",
      "secondary": "secondary",
      "info": "info",
      "success": "success",
      "warning": "warning",
      "danger": "danger"
    },
    style: {
      "plain": "plain",
      "icon": "icon"
    },
  },
  mutations: {
  },
  actions: {
    enableNotification({ state }, object) {
      const { title, message, type, from, align, time } = object;
      $.notify({
        icon: 'flaticon-alarm-1',
        title: title,
        message: message,
      },{
        type: state.state[type],
        placement: {
          from: state.from[from],
          align: state.align[align]
        },
        time: time,
      });
    },
  },
  getters: {},
};

export default Notification;
