<script setup>
import { ref } from "vue";
const input = ref(null);
</script>
<template>
  <div class="row bg-white">
    <div class="col-md-12">
      <label for="upload-file" class="w-100 h-100 p-4 border-dashed cursor-pointer">
        <div class="d-flex flex-column justify-content-center align-items-center text-gray">
          <i class="fa-solid fa-cloud-arrow-up fa-2xl"></i>
          <h3 class="font-weight-bold text-uppercase mt-3">{{ this.$t("titleImportFile") }}</h3>
          <p>{{ fileName }}</p>
          <span class="error">{{ errorFile }}</span>
        </div>
      </label>
      <input
        type="file"
        class="form-control"
        id="upload-file"
        aria-describedby="basic-addon3"
        :value="modelValue"
        @change="$emit('handleImportFile', $event)"
        ref="input"
        hidden
        accept="application/json"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    errorFile: {
      type: String,
      required: false,
      default: "",
    },
    fileName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.border-dashed {
  border: 3px dashed #EFF1F2;
}
.text-gray {
  color: #98A6AD;
}
.error {
  font-family: var(--font-label);
  font-size: 14px;
  color: red;
  margin-left: 8px;
}
</style>
