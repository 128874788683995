import { format, eachDayOfInterval, isSaturday, isSunday } from 'date-fns';
import { timeService } from "@/services";
export const raService = {
  updateStartFinishDateRA,
  updateLastStartFinishDateRA,
  updateRA,
  updateTaskHoursRA,
  updateTaskDaysRA,
  updateAfterChangeWorkingHours
};

//Cập nhật lại ngày tháng và giờ làm việc của task cha sau khi thay đổi ngày tháng làm việc task con
function updateStartFinishDateRA(object, settings) {
  for (const key in object) {
    if (object[key].sub_task && Object.keys(object[key].sub_task).length > 0) {
      updateStartFinishDateRA(object[key].sub_task, settings);
      let minStartDate = new Date(8640000000000000);
      let maxFinishDate = new Date(-8640000000000000);
      let hours = 0;
      try {
        for(const sub_key in object[key].sub_task){
          const currentSubTaskStartDate = new Date(object[key].sub_task[sub_key].start_date);
          const currentSubTaskFinishDate = new Date(object[key].sub_task[sub_key].finish_date);
          if(currentSubTaskStartDate < minStartDate) minStartDate = currentSubTaskStartDate;
          if(currentSubTaskFinishDate > maxFinishDate) maxFinishDate = currentSubTaskFinishDate;
          hours += parseFloat(object[key].sub_task[sub_key].hours);
        }
        if(isNaN(hours) || !hours)  throw new Error("Invalid total of hours");
        object[key].start_date = format(minStartDate, "yyyy-MM-dd");
        object[key].finish_date = format(maxFinishDate, "yyyy-MM-dd");
        object[key].hours = hours.toString();
        object[key].days = parseFloat(hours/parseInt(settings.working_hours)).toString();
      } catch (e) {
        console.log(e.message);
      }
    } else {
      try {
        const start_date = new Date(object[key].start_date);
        const finish_date = new Date(object[key].finish_date);
        const allDays = eachDayOfInterval({ start: start_date, end: finish_date });
        let workingDays = allDays.filter(day => !isSunday(day)).length;
        if(settings.weekends === "0") {
          workingDays = allDays.filter(day => !isSaturday(day) && !isSunday(day)).length;
        }
        const current_hours_parse_days = Math.ceil(parseFloat(object[key].effort.requirements_analysis.hours) / parseFloat(settings.working_hours)); //số ngày dựa vào hours thực tế
        if(current_hours_parse_days != workingDays) {
          object[key].effort.requirements_analysis.hours = (workingDays*parseFloat(settings.working_hours)).toString();
          object[key].effort.requirements_analysis.days = parseFloat(parseFloat(object[key].effort.requirements_analysis.hours)/parseFloat(settings.working_hours)).toString();
          object[key].days = object[key].effort.requirements_analysis.days;
          object[key].hours = object[key].effort.requirements_analysis.hours;
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }
}

//Cập nhật thời gian tổng và ngày bắt đầu và kết thúc của preparation
function updateLastStartFinishDateRA(object, parent, root) {
  let minStartDate = new Date(8640000000000000);
  let maxFinishDate = new Date(-8640000000000000);
  let hours = 0;
  let days = 0;
  try {
    if(Object.keys(object).length > 0) {
      for (const key in object) {
        const currentSubTaskStartDate = new Date(object[key].start_date);
        const currentSubTaskFinishDate = new Date(object[key].finish_date);
        if(currentSubTaskStartDate < minStartDate) minStartDate = currentSubTaskStartDate;
        if(currentSubTaskFinishDate > maxFinishDate) maxFinishDate = currentSubTaskFinishDate;
        hours += parseFloat(object[key].hours);
        days += parseFloat(object[key].days);
      }
      parent.start_date = format(minStartDate, "yyyy-MM-dd");
      parent.finish_date = format(maxFinishDate, "yyyy-MM-dd");
      parent.hours = hours.toString();
      parent.days = days.toString();
    } else {
      resetParent(parent);
      if(Object.keys(root.programming_and_testing.task).length === 0){
        resetParent(root.programming_and_testing);
        resetParent(root);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

function resetParent(parent) {
  parent.start_date = "";
  parent.finish_date = "";
  parent.hours = "";
  parent.days = "";
}

//Gọi chung cả 2 phương thức khi có sự thay đổi
function updateRA(object, parent, settings, root) {
  updateStartFinishDateRA(object, settings);
  updateLastStartFinishDateRA(object, parent, root);
}

//Cập nhật lại giờ làm việc sau khi thay đổi cột hours
function updateTaskHoursRA(obj, targetIndex, settings) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (obj[key].index === targetIndex) {
        if(!obj[key].effort.requirements_analysis.hours)  obj[key].effort.requirements_analysis.hours = settings.working_hours;
        try {
          const hours = parseFloat(obj[key].effort.requirements_analysis.hours);
          if(isNaN(hours)) throw new Error("Invalid hours");
          let days = hours / parseFloat(settings.working_hours);
          const current_date = new Date(obj[key].start_date);
          let finish_date = current_date;
          finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
          obj[key].finish_date = format(finish_date, "yyyy-MM-dd");
          obj[key].hours = obj[key].effort.requirements_analysis.hours;
          obj[key].days = days.toString();
          obj[key].effort.requirements_analysis.days = days.toString();
          return true;
        } catch (e) {
          console.log(e.message);
          resetError(obj[key], settings);
        }
      } else if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
        if(updateTaskHoursRA(obj[key].sub_task, targetIndex, settings))
          return true;
      }
    }
  }
  return false;
}

//Cập nhật lại giờ làm việc sau khi thay đổi cột days
function updateTaskDaysRA(obj, targetIndex, settings) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (obj[key].index === targetIndex) {
        try {
          if(!obj[key].effort.requirements_analysis.days)  obj[key].days = "1";
          //Lấy ra các biến giờ của task hiện tại và day của settings
          const days = parseFloat(obj[key].effort.requirements_analysis.days);
          let working_hours = settings.working_hours;
          const current_date = new Date(obj[key].start_date);
          let finish_date = current_date;
          finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
          obj[key].finish_date = format(finish_date, "yyyy-MM-dd");
          obj[key].hours = (days*working_hours).toString();
          obj[key].effort.requirements_analysis.hours = (days*working_hours).toString();
          obj[key].days = obj[key].effort.requirements_analysis.days;
          return true;
        } catch (e) {
          console.log(e.message);
          resetError(obj[key], settings);
        }
      } else if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
        if(updateTaskDaysRA(obj[key].sub_task, targetIndex, settings))
          return true;
      }
    }
  }
  return false;
}

//Cập nhật lại giờ và ngày khi thay đổi thời gian làm việc 1 ngày
function updateAfterChangeWorkingHours(obj, settings) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      //Lấy ra các biến giờ của task hiện tại và day của settings
      try {
        const hours = parseFloat(obj[key].effort.requirements_analysis.hours);
        if(isNaN(hours)) throw new Error("Invalid hours");
        let days = hours / parseFloat(settings.working_hours);
        const current_date = new Date(obj[key].start_date);
        let finish_date = current_date;
        finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
        obj[key].finish_date = format(finish_date, "yyyy-MM-dd");
        obj[key].days = days.toString();
        obj[key].effort.requirements_analysis.days = days.toString();
        obj[key].hours = obj[key].effort.requirements_analysis.hours;
        obj[key].created_at = Math.random().toString(30);
        if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
          updateAfterChangeWorkingHours(obj[key].sub_task, settings);
        }
      } catch (e) {
        console.log(e.message);
        resetError(obj[key], settings);
      }
    }
  }
  return true;
}

function resetError(object, settings) {
  object.finish_date = object.start_date;
  object.days = object.effort.requirements_analysis.days = "1";
  object.hours = object.effort.requirements_analysis.hours = settings.working_hours;
}