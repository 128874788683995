import { preparationService, service } from "../../services";
const Delivery = {
  namespaced: true,
  state: {
    delivery: {
      next_delivery_id: 1,
      start_date: "",
      finish_date: "",
      hours: "",
      days: "",
      task: {}
    },
    isDeleteDelivery: false,
  },
  mutations: {
    //Delivery
    addTaskDelivery(state , sample) {
      sample = JSON.parse(JSON.stringify(sample));
      const prevIndex = Object.keys(state.delivery.task).length;
      const currentIndex = prevIndex + 1;
      const index = "3."+currentIndex
      sample.index = index;
      state.delivery.task[currentIndex] = sample;
    },
    addSubTaskDelivery(state, object) {
      const { index, sample } = object;
      const sample_object = JSON.parse(JSON.stringify(sample));
      service.addObjectByIndex(state.delivery.task, index, sample_object, 3);
    },
    updateTaskDelivery(state) {
      const tmp_object = {};
      service.updateIDTask(state.delivery.task, tmp_object, 3);
      state.delivery.task = tmp_object;
    },
    deleteTaskDelivery(state, index) {
      const isDelete = service.deleteTask(state.delivery.task, index);
      state.isDeleteDelivery = isDelete;
    },
    updateTaskHoursDelivery(state, block) {
      const { index, settings } = block;
      preparationService.updateTaskHoursPreparation(state.delivery.task, index, settings);
    },
    updateTaskDaysDelivery(state, block) {
      const { index, settings } = block;
      preparationService.updateTaskDaysPreparation(state.delivery.task, index, settings);
    },
    updateAfterChangeWorkingHours(state, settings) {
      preparationService.updateAfterChangeWorkingHours(state.delivery.task, settings);
      preparationService.updateLastStartFinishDate(state.delivery.task, state.delivery);
    },
    updateDelivery(state, rootState) {
      const settings = rootState.work.project_details;
      preparationService.updatePreparation(state.delivery.task, 
        state.delivery, settings, 
        rootState.work.summary.master_plan_and_schedule.start_date,
      );
    },
  },
  actions: {
    addTaskDelivery({ commit, rootState }, sample) {
      commit("addTaskDelivery", sample);
      commit("updateDelivery", rootState);
    },
    addSubTaskDelivery({ commit, rootState }, object) {
      commit("addSubTaskDelivery", JSON.parse(JSON.stringify(object)));
      commit("updateDelivery", rootState);
    },
    deleteTaskDelivery({ commit, state }, index) {
      commit("deleteTaskDelivery", index);
      return state.isDeleteDelivery;
    },
    updateTaskDelivery({ commit, rootState }) {
      commit("updateTaskDelivery", rootState.work.project_details);
      commit("updateDelivery", rootState);
    },
    updateTimeLineDelivery({ commit, rootState }) {
      commit("updateDelivery", rootState);
    },
    updateTaskHoursDelivery({ commit, rootState }, index) {
      commit("updateTaskHoursDelivery", { index: index, settings: rootState.work.project_details });
      commit("updateDelivery", rootState);
    },
    updateTaskDaysDelivery({ commit, rootState }, index) {
      commit("updateTaskDaysDelivery", { index: index, settings: rootState.work.project_details });
      commit("updateDelivery", rootState);
    },
    updateAfterChangeWorkingHours({ commit, rootState }) {
      commit("updateAfterChangeWorkingHours", rootState.work.project_details);
      commit("updateDelivery", rootState);
    }
  },
  getters: {},
};

export default Delivery;
