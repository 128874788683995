export default {
  "Summary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])};fn.source="Summary";return fn;})(),
  "Language": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])};fn.source="Language";return fn;})(),
  "Chart": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graph showing the working process"])};fn.source="Graph showing the working process";return fn;})(),
  "ProjectName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Name"])};fn.source="Project Name";return fn;})(),
  "Version": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])};fn.source="Version";return fn;})(),
  "EstimationDate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimation Date"])};fn.source="Estimation Date";return fn;})(),
  "ProjectShortDescription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Short Description"])};fn.source="Project Short Description";return fn;})(),
  "AssumptionsAndConstraints": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assumptions And Constraints"])};fn.source="Assumptions And Constraints";return fn;})(),
  "QuestionFrom": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Question From ", _interpolate(_list(0))])};fn.source="Question From {0}";return fn;})(),
  "Quotation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotation"])};fn.source="Quotation";return fn;})(),
  "TotalCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Cost"])};fn.source="Total Cost";return fn;})(),
  "ProjectCostStructure": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Cost Structure"])};fn.source="Project Cost Structure";return fn;})(),
  "DevelopmentCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Cost"])};fn.source="Development Cost";return fn;})(),
  "SupportCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Cost"])};fn.source="Support Cost";return fn;})(),
  "LiscenceCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liscence Cost"])};fn.source="Liscence Cost";return fn;})(),
  "OtherCost": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Cost"])};fn.source="Other Cost";return fn;})(),
  "Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])};fn.source="Rate";return fn;})(),
  "TotalInManDays": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total In Man Days"])};fn.source="Total In Man Days";return fn;})(),
  "TotalInManHours": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total In Man Hours"])};fn.source="Total In Man Hours";return fn;})(),
  "TotalInManMonths": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total In Man Months"])};fn.source="Total In Man Months";return fn;})(),
  "MasterPlanAndSchedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master Plan And Schedule"])};fn.source="Master Plan And Schedule";return fn;})(),
  "StartDate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])};fn.source="Start Date";return fn;})(),
  "FirstDemo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Demo"])};fn.source="First Demo";return fn;})(),
  "FinishDate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Date"])};fn.source="Finish Date";return fn;})(),
  "UAT": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAT"])};fn.source="UAT";return fn;})(),
  "DevelopmentEffortEstimation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Effort Estimation"])};fn.source="Development Effort Estimation";return fn;})(),
  "TheEstimationWillBasedOn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Estimation Will Based On"])};fn.source="The Estimation Will Based On";return fn;})(),
  "man-hour": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["man-hour"])};fn.source="man-hour";return fn;})(),
  "man-day": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["man-day"])};fn.source="man-day";return fn;})(),
  "Weekend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday and Sunday"])};fn.source="Saturday and Sunday";return fn;})(),
  "Sunday": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])};fn.source="Sunday";return fn;})(),
  "WeeklyDayOff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Day Off"])};fn.source="Weekly Day Off";return fn;})(),
  "DateFormat": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Format"])};fn.source="Date Format";return fn;})(),
  "Day": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])};fn.source="Day";return fn;})(),
  "Month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])};fn.source="Month";return fn;})(),
  "Year": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])};fn.source="Year";return fn;})(),
  "Preparation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation"])};fn.source="Preparation";return fn;})(),
  "WorkItem": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work Item"])};fn.source="Work Item";return fn;})(),
  "Total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])};fn.source="Total";return fn;})(),
  "AddTask": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Task"])};fn.source="Add Task";return fn;})(),
  "AddSubTask": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Sub Task"])};fn.source="Add Sub Task";return fn;})(),
  "RemoveTask": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Task"])};fn.source="Remove Task";return fn;})(),
  "WorkingHours": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Hours"])};fn.source="Working Hours";return fn;})(),
  "Hours": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])};fn.source="Hours";return fn;})(),
  "Implementation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation"])};fn.source="Implementation";return fn;})(),
  "Effort": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effort"])};fn.source="Effort";return fn;})(),
  "RequirementAnalysis": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirement Analysis"])};fn.source="Requirement Analysis";return fn;})(),
  "SystemDesign": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Design"])};fn.source="System Design";return fn;})(),
  "Coding": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coding"])};fn.source="Coding";return fn;})(),
  "FE": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FE"])};fn.source="FE";return fn;})(),
  "BE": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE"])};fn.source="BE";return fn;})(),
  "Testing/BugsFixing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testing / Bugs Fixing"])};fn.source="Testing / Bugs Fixing";return fn;})(),
  "Quantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])};fn.source="Quantity";return fn;})(),
  "SubTotal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])};fn.source="Sub Total";return fn;})(),
  "Delivery": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])};fn.source="Delivery";return fn;})(),
  "Task": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])};fn.source="Task";return fn;})(),
  "Scale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])};fn.source="Scale";return fn;})(),
  "Development": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])};fn.source="Development";return fn;})(),
  "Warranty": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Warranty (", _interpolate(_list(0)), " days afer UAT)"])};fn.source="Warranty ({0} days afer UAT)";return fn;})(),
  "FixLostBugsAndIssues": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix lost bugs & issues"])};fn.source="Fix lost bugs & issues";return fn;})(),
  "ProjectManagement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Management"])};fn.source="Project Management";return fn;})(),
  "ProgrammingAndTesting": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programming And Testing"])};fn.source="Programming And Testing";return fn;})(),
  "SQA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQA"])};fn.source="SQA";return fn;})(),
  "TotalProjectEffort": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Project Effort"])};fn.source="Total Project Effort";return fn;})(),
  "Note": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])};fn.source="Note";return fn;})(),
  "titleAlertDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Are you sure you want to delete the task ", _interpolate(_list(0)), "?"])};fn.source="Are you sure you want to delete the task {0}?";return fn;})(),
  "messageAlertDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won't be able to revert this!"])};fn.source="You won't be able to revert this!";return fn;})(),
  "textConfirmAlertDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, delete it!"])};fn.source="Yes, delete it!";return fn;})(),
  "titleNoticeSuccessDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted!"])};fn.source="Deleted!";return fn;})(),
  "textNoticeSuccessDelete": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Task ", _interpolate(_list(0)), " has been deleted.!"])};fn.source="Task {0} has been deleted.!";return fn;})(),
  "titleUpdateInformationCompany": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update company information"])};fn.source="Update company information";return fn;})(),
  "titleImportFile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import data file"])};fn.source="Import data file";return fn;})(),
  "TaskName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task name"])};fn.source="Task name";return fn;})(),
  "TotalTime": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total time"])};fn.source="Total time";return fn;})(),
  "ConfirmStoreJson": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to download an archive of the current project data so that you can edit it later?"])};fn.source="Do you want to download an archive of the current project data so that you can edit it later?";return fn;})(),
  "SaveChanges": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])};fn.source="Save changes";return fn;})(),
  "TitleErrorFileJson": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The JSON file is not in the correct format!"])};fn.source="The JSON file is not in the correct format!";return fn;})(),
  "ErrorFileJson": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The input file is not in the correct format or data standard"])};fn.source="The input file is not in the correct format or data standard";return fn;})(),
  "TitleErrorExport": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Error while extracting ", _interpolate(_list(0)), " file!"])};fn.source="Error while extracting {0} file!";return fn;})(),
  "MessageErrorExport": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Unable to extract ", _interpolate(_list(0)), " file because of an error. Try again!"])};fn.source="Unable to extract {0} file because of an error. Try again!";return fn;})(),
  "Success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])};fn.source="Success!";return fn;})(),
  "ImportSuccessFile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imported data files into the system successfully!"])};fn.source="Imported data files into the system successfully!";return fn;})(),
  "SuccessDeleteFile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data deletion successful!"])};fn.source="Data deletion successful!";return fn;})(),
  "SuccessImportFile": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Successfully converted data to ", _interpolate(_list(0)), " and downloaded"])};fn.source="Successfully converted data to {0} and downloaded";return fn;})(),
  "NoticeUpdateCompanySuccessful": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated company information!"])};fn.source="Successfully updated company information!";return fn;})(),
  "NoticeUpdateCompanyFailed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating company information failed!"])};fn.source="Updating company information failed!";return fn;})(),
  "CompanyName": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])};fn.source="Company Name";return fn;})(),
  "CompanyAddress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Address"])};fn.source="Company Address";return fn;})(),
  "CompanyPhone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Phone Number"])};fn.source="Company Phone Number";return fn;})(),
  "CompanyEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Email"])};fn.source="Company Email";return fn;})(),
  "TheHourIsVacant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion time cannot be empty!"])};fn.source="Completion time cannot be empty!";return fn;})(),
  "TheHourIsVacantMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The work completion time of item ", _interpolate(_list(0)), " you just left blank should be reset to the previous working hour!"])};fn.source="The work completion time of item {0} you just left blank should be reset to the previous working hour!";return fn;})(),
  "TheDayIsVacant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion date cannot be empty!"])};fn.source="Completion date cannot be empty!";return fn;})(),
  "TheDayIsVacantMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The work completion date of the last ", _interpolate(_list(0)), " entry you left blank should be reset to the previous working day!"])};fn.source="The work completion date of the last {0} entry you left blank should be reset to the previous working day!";return fn;})(),
  "ResetData": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reset the data?"])};fn.source="Are you sure you want to reset the data?";return fn;})()
}