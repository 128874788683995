<template>
  <div class="sidebar sidebar-style-2">
    <div class="sidebar-wrapper scrollbar scrollbar-inner">
      <div class="sidebar-content">
        <ul class="nav nav-primary">
          <li class="nav-item">
            <router-link
              class="collapsed"
              to="/"
              :class="{ 'current-page': currentRouteLink == 'summary' }"
            >
              <i class="fas fa-home"></i>
              <p>{{ $t("Summary") }}</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="development"
              :class="{ 'current-page': currentRouteLink == 'development' }"
            >
              <i class="fas fa-layer-group"></i>
              <p>{{ $t("Development") }}</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" @click="handleExportPDF">
              <i class="fa-solid fa-file-pdf"></i>
              <p>PDF</p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="handleExportExcel">
              <i class="fa-solid fa-file-excel"></i>
              <p>EXCEL</p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" data-toggle="modal" data-target="#importData">
              <i class="fa-solid fa-file-import"></i>
              <p>IMPORT</p>
            </a>
          </li>
          <li class="nav-item">
            <a href="#" @click="handleResetData">
              <i class="fa-solid fa-spinner"></i>
              <p>RESET</p>
            </a>
          </li>
          <li class="nav-item dropdown hidden-caret">
            <a
              class="dropdown-toggle profile-pic"
              data-toggle="dropdown"
              href="#"
              aria-expanded="false"
            >
              <div class="avatar-sm">
                <i class="fas fas fa-globe"></i>
                <p>{{ $t("Language") }}: {{ languages[this.$i18n.locale] }}</p>
              </div>
            </a>
            <ul class="dropdown-menu animated fadeIn">
              <li>
                <a class="dropdown-item" @click="handleChangeLanguage('vn')" href="#"
                  >Việt Nam</a
                >
                <a class="dropdown-item" @click="handleChangeLanguage('en')" href="#"
                  >English</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div>
    <Alert
      :idAlert="'id-ErrorBeforeConvertToExcelPDF'"
      title="Thông tin dự án chưa đầy đủ!"
      message="Hãy cập nhật thông tin dự án trước khi xuất tệp!"
      typeAlert="error"
    >
      <button class="hidden" id="id-ErrorBeforeConvertToExcelPDF">Click</button>
    </Alert>
  </div>
  <Modal :modalTitle="$t('titleImportFile')" ref="modalImportData" idModal="importData" classButtonClose="closeImportData" classModal="import-data-class">
    <InputFile v-model="upload_file" @handleImportFile="handleImportFile" :fileName="file_name" :errorFile="error_json" />
    <div class="row mt-2">
      <div class="col-md-12 d-flex justify-content-end">
        <button class="btn btn-primary" @click="handleImportData">{{ this.$t("SaveChanges") }}</button>
      </div>
    </div>
  </Modal>
</template>

<script>
/* eslint-disable */
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { service } from "@/services";
import { mapState } from "vuex";
import { format } from 'date-fns';
import ExcelJS from 'exceljs';
import { mergeCell, editHoursDays, setFrameSummary, setFrameDevelopment, resetCount } from "@/services/excel.service.js";
import { saveAs } from 'file-saver';
import Alert from "@/components/Alert.vue";
import InputFile from "../components/InputFile.vue";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  data() {
    return {
      languages: {
        en: "English",
        vn: "Việt Nam",
      },
      upload_file: "",
      json_content: {},
      error_json: "",
      file_name: "",
    };
  },
  components: {
    Alert,
    InputFile,
  },
  computed: {
    ...mapState("work", ["project_details"]),
    ...mapState("preparation", ["preparation"]),
    ...mapState("development", ["requirements_analysis"]),
    ...mapState("development", ["programming_and_testing"]),
    ...mapState("delivery", ["delivery"]),
    ...mapState("work", ["summary"]),
    currentRouteLink() {
      return this.$route.name;
    },
    total_preparation() {
      let value = parseFloat(this.preparation.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.preparation.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_ra() {
      let value = parseFloat(this.requirements_analysis.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.requirements_analysis.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_pat() {
      let value = parseFloat(this.programming_and_testing.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.programming_and_testing.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_development() {
      let value = parseFloat(this.delivery.hours) + 
      parseFloat(this.$store.state.development.hours) + parseFloat(this.preparation.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.delivery.days) + 
        parseFloat(this.$store.state.development.days) + parseFloat(this.preparation.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_delivery() {
      let value = parseFloat(this.delivery.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.delivery.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_implementation() {
      let value = parseFloat(this.$store.state.development.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.$store.state.development.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_warranty() {
      let value = parseFloat(this.summary.warranty.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.summary.warranty.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_project_management() {
      let value = parseFloat(this.summary.project_managerment_sqa.project_management.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.summary.project_managerment_sqa.project_management.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_sqa() {
      let value = parseFloat(this.summary.project_managerment_sqa.sqa.hours);
      if(this.project_details.estimation_base === "1") {
        value = parseFloat(this.summary.project_managerment_sqa.sqa.days);
      }
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    total_hours() {
      const preparation_hours = parseFloat(this.$store.state.preparation.preparation.hours);
      const development_hours = parseFloat(this.$store.state.development.hours);
      const delivery_hours = parseFloat(this.$store.state.delivery.delivery.hours);
      const warranty_hours = parseFloat(this.$store.state.work.summary.warranty.hours);
      const project_management_hours = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.project_management.hours);
      const sqa_hours = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.sqa.hours);
      const totalHours = (isNaN(preparation_hours) ? 0 : preparation_hours) + 
      (isNaN(development_hours) ? 0 : development_hours) +
      (isNaN(delivery_hours) ? 0 : delivery_hours) + (isNaN(warranty_hours) ? 0 : warranty_hours)
      + (isNaN(project_management_hours) ? 0 : project_management_hours)
      + (isNaN(sqa_hours) ? 0 : sqa_hours);
      return totalHours.toString();
    },
    total_days() {
      const preparation_days = parseFloat(this.$store.state.preparation.preparation.days);
      const development_days = parseFloat(this.$store.state.development.days);
      const delivery_days = parseFloat(this.$store.state.delivery.delivery.days);
      const warranty_days = parseFloat(this.$store.state.work.summary.warranty.days);
      const project_management_days = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.project_management.days);
      const sqa_days = 
      parseFloat(this.$store.state.work.summary.project_managerment_sqa.sqa.days);
      const totalDays = (isNaN(preparation_days) ? 0 : preparation_days) + 
      (isNaN(development_days) ? 0 : development_days) +
      (isNaN(delivery_days) ? 0 : delivery_days) + (isNaN(warranty_days) ? 0 : warranty_days)
      + (isNaN(project_management_days) ? 0 : project_management_days)
      + (isNaN(sqa_days) ? 0 : sqa_days);
      return totalDays.toString();
    },
    total_months() {
      let totalMonths = 0;
      if(this.total_days != "0") {
        if(this.project_details.weekends === "0") {
          totalMonths = parseFloat(this.total_days) / 22;
        } else {
          totalMonths = parseFloat(this.total_days) / 26;
        }
      }
      return totalMonths.toFixed(2);
    },
    companyName() {
      return process.env.VUE_APP_COMPANY_NAME;
    },
    companyAddress() {
      return process.env.VUE_APP_COMPANY_ADDRESS;
    },
    companyContact() {
      return process.env.VUE_APP_CONTACT;
    },
    work_base() {
      return {
        project_details: {
          estimation_base: "0",
          working_hours: "8",
          weekends: "0", //0 is Saturday and Sunday, 1 is Sunday
          date_format: "dd-MM-yyyy",
        },
        summary: {
          project_name: {
            project_name: "",
            version: "",
            estimation_date: ""
          },
          project_short_description: "",
          assumptions_and_constraints: "",
          question: "",
          quotation: {
            currency: "Euro",
            total_cost: "",
            development_cost: {
              rate: "0",
              total_in_man_hours: "",
              total_in_man_days: "",
              total_in_man_months: "",
              development_cost: ""
            },
            support_cost: "",
            license_cost: "",
            other_cost: "",
          },
          warranty: {
            start_date: "",
            finish_date: "",
            hours: "",
            days: "",
            scale: "",
          },
          project_managerment_sqa: {
            project_management: {
              hours: "0",
              days: "0",
              scale: "0",
            },
            sqa: {
              hours: "0",
              days: "0",
              scale: "0",
            }
          },
          master_plan_and_schedule: {
            start_date: "",
            first_demo: "",
            finish_date: "",
            UAT: ""
          }
        },
      }
    },
    preparation_base() {
      return {
        preparation: {
          next_preparation_id: 1,
          start_date: "",
          finish_date: "",
          hours: "",
          days: "",
          task: {}
        },
        isDeletePreparation: false,
      }
    },
    development_base() {
      return {
        start_date: "",
        finish_date: "",
        hours: "",
        days: "",
        requirements_analysis: {
          start_date: "",
          finish_date: "",
          hours: "",
          days: "",
          task: {}
        },
        programming_and_testing: {
          start_date: "",
          finish_date: "",
          hours: "",
          days: "",
          requirements_analysis: {
            days: "",
            hours: "",
          },
          system_design: {
            days: "",
            hours: "",
          },
          FE: {
            days: "",
            hours: "",
          },
          BE: {
            days: "",
            hours: "",
          },
          testing_bugs_fixing: {
            days: "",
            hours: "",
          },
          task: {},
        },
        isDeleteRA: false,
      }
    },
    delivery_base() {
      return {
        delivery: {
          next_delivery_id: 1,
          start_date: "",
          finish_date: "",
          hours: "",
          days: "",
          task: {}
        },
        isDeleteDelivery: false,
      }
    },
  },
  methods: {
    handleChangeLanguage(language) {
      this.$i18n.locale = language;
      localStorage.setItem('language', language);
    },
    editHoursDays(object) {
      let value = parseFloat(object.hours);
      if(this.project_details.estimation_base === "1")
        value = parseFloat(object.days);
      if(Number.isNaN(value)) return "";
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(2);
      }
    },
    formatFloat(value) {
      let val = parseFloat(value);
      if(isNaN(val)) return '';
      else return val.toFixed(2);
    },
    convertToFormatDate(date) {
      if(date) {
        const formatDate = new Date(date);
        if(!isNaN(formatDate))
          return format(formatDate, 'dd-MM-yyyy')
        else return '';
      } else return '';
    },
    async handleExportPDF() {
      try {
        const preparation = this.preparation;
        const requirements_analysis = this.requirements_analysis;
        const programming_and_testing = this.programming_and_testing;
        const delivery = this.delivery;
        if(!service.checkBeforeConvertToPDF({
          preparation: preparation, 
          requirements_analysis: requirements_analysis, 
          programming_and_testing: programming_and_testing,
          delivery: delivery,
          summary: this.summary,
        })) {
          const button = document.getElementById("id-ErrorBeforeConvertToExcelPDF");
          button.click();
        } else {
          const imagePath = require("../assets/img/logo/logo.png");
          let imageElement = new Image();
          imageElement.src = imagePath;
          imageElement.onload = async () => {
            const dataImage = await fetch(imagePath)
            const blob = await dataImage.blob();
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result;
              const width = imageElement.width;
              const height = imageElement.height;
              const desiredHeight = 25;
              const aspectRatio = width / height;
              const calculatedWidth = desiredHeight * aspectRatio;
              const key_effort = ['requirements_analysis', 'system_design', 'FE', 'BE', 'testing_bugs_fixing'];
              const preparation_tasks = [];
              service.convertTasksToArray(preparation.task, preparation_tasks, this.project_details, 'pre', 'dd-MM-yyyy');
              const requirements_analysis_tasks = [];
              service.convertTasksToArray(requirements_analysis.task, requirements_analysis_tasks, this.project_details, 'dev', 'dd-MM-yyyy');
              const programming_and_testing_tasks = [];
              service.convertTasksToArray(programming_and_testing.task, programming_and_testing_tasks, this.project_details, 'dev', 'dd-MM-yyyy');
              const delivery_tasks = [];
              service.convertTasksToArray(delivery.task, delivery_tasks, this.project_details, 'del', 'dd-MM-yyyy');
              const columnWhite = {fontSize: 8, margin: [4, 0, 0, 0], border: [true, false, true, true], borderColor: ['#F5F4F6', '#F5F4F6', '#F5F4F6', '#F5F4F6'],};
              const columnGray = {fontSize: 8, margin: [4, 0, 0, 0], fillColor: "#F5F4F6", border: [false, false, true, true], borderColor: ['white', 'white', 'white', 'white'],};
              const configTitle = {fontSize: 8, fillColor: "#FAA63D", margin: [4, 0, 0, 0], color: "white", bold: true, border: [false, false, true, true], borderColor: ['white', 'white', 'white', 'white'],};
              const configHeader = {fontSize: 8, fillColor: "#227ABE", margin: [4, 0, 0, 0], color: "white", bold: true, border: [false, false, true, true], borderColor: ['white', 'white', 'white', 'white'],};
              const configFooter = {fontSize: 8, fillColor: "#0E8C6B", margin: [4, 0, 0, 0], color: "white", bold: true, border: [false, false, true, true], borderColor: ['white', 'white', 'white', 'white'],};
              const defaultStyle = {
                font: "Roboto",
                fontSize: 11,
              };
              var docDefinition = {
                defaultStyle: defaultStyle,
                pageMargins: [40, 60, 40, 60],
                header: {
                    //Header 1
                    columns: [
                      {
                        height: desiredHeight,
                        width: calculatedWidth,
                        image: base64String
                      },
                      {
                        width: 'auto',
                        text: [
                          { text: this.companyName+"\n", fontSize: 13, bold: true },
                          { text: this.companyAddress+"\n"},
                          { text: this.companyContact+"\n"}
                        ],
                        margin: [35, 0, 0, 0]
                      },
                    ],
                  },
                content: [
                  //Summary 1
                  {
                    columns: [
                      {
                        text: this.$t("Summary").toUpperCase(), fontSize: 22, bold: true, alignment: 'center', margin: [0, 25, 0, 0],
                      },
                    ],
                  },
                  //Project Name 4
                  {
                    text: "1. "+this.$t("ProjectName"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 10, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.$t("ProjectName")+":\t", bold: true },
                          { text: this.summary.project_name.project_name+"\n"},
                        ],
                        width: "100%",
                      },
                    ]
                  },
                  {
                    margin: [0, 7, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.$t("Version")+":\t", bold: true},
                          { text: this.summary.project_name.version+"\n"},
                        ],
                        width: "50%",
                      },
                      {
                        text: [
                          { text: this.$t("EstimationDate")+":\t", bold: true },
                          { text: this.convertToFormatDate(this.summary.project_name.estimation_date)+"\n"},
                        ],
                        width: "50%"
                      },
                    ],
                    width: "100%",
                  },
                  //End Project Name
                  //Project Short Description 6
                  {
                    text: "2. "+this.$t("ProjectShortDescription"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 10, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.summary.project_short_description+"\n"},
                        ],
                        width: "100%",
                      },
                    ]
                  },
                  //End Project Short Description
                  //Assumptions & Constraints 8
                  {
                    text: "3. "+this.$t("AssumptionsAndConstraints"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 10, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.summary.assumptions_and_constraints+"\n"},
                        ],
                        width: "100%",
                      },
                    ]
                  },
                  //End Assumptions & Constraints
                  //Question from 10
                  {
                    text: "4. "+this.$t("QuestionFrom", ['BHTech']), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 10, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.summary.question+"\n"},
                        ],
                        width: "100%",
                      },
                    ]
                  },
                  //End Assumptions & Constraints
                  //Quotation 12
                  {
                    text: "5. "+this.$t("Quotation"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 10, 0, 0],
                    layout: 'lightHorizontalLines', // optional
                    table: {
                      // headers are automatically repeated if the table spans over multiple pages
                      // you can declare how many rows should be treated as headers
                      headerRows: 0,
                      widths: [ '33.3%', '33.3%', '33.3%', '33.3%' ],
                      body: [
                        [ {text: this.$t("TotalCost"), ...configHeader, fontSize: 11},
                          {text: this.summary.quotation.currency, ...configHeader, fontSize: 11},
                          {text: this.summary.quotation.total_cost, ...configHeader, fontSize: 11},
                        ],
                        [ {text: this.$t("ProjectCostStructure"), ...columnGray, colSpan: 3,  fontSize: 11}, '', '' ],
                        [ {text: this.$t("DevelopmentCost"), margin: [4, 26, 0, 0], rowSpan: 4, border: [true, true, true, true], borderColor: ['#F5F4F6', '#F5F4F6', '#F5F4F6', '#F5F4F6'],},
                          {text: this.$t("Rate"), margin: [4, 0, 0, 0],},
                          {text: this.summary.quotation.development_cost.rate === '0' ? this.$t("man-hour") : this.$t("man-day"), ...columnWhite, fontSize: 11}
                        ],
                        [ '',
                          {text: this.summary.quotation.development_cost.rate === '0' ? this.$t("TotalInManHours") : this.$t("TotalInManDays"), margin: [4, 0, 0, 0], fillColor: "#F5F4F6"},
                          {text: this.summary.quotation.development_cost.rate === '0' ? this.total_hours : this.total_days, margin: [4, 0, 0, 0], fillColor: "#F5F4F6"}
                        ],
                        [ '',
                          {text: this.$t("TotalInManMonths"), margin: [4, 0, 0, 0],},
                          {text: this.total_months, ...columnWhite, fontSize: 11}
                        ],
                        [ '',
                          {text: this.$t("DevelopmentCost"), margin: [4, 0, 0, 0], fillColor: "#F5F4F6"},
                          {text: this.summary.quotation.development_cost.development_cost, margin: [4, 0, 0, 0], fillColor: "#F5F4F6"}
                        ],
                        [ {text: this.$t("SupportCost"), colSpan: 2, ...columnWhite, fontSize: 11},
                          '',
                          {text: this.summary.quotation.support_cost, ...columnWhite, fontSize: 11}
                        ],
                        [ {text: this.$t("LiscenceCost"), ...columnGray, colSpan: 2, fontSize: 11},
                          '',
                          {text: this.summary.quotation.license_cost, ...columnGray, fontSize: 11}
                        ],
                        [ {text: this.$t("OtherCost"), colSpan: 2, ...columnWhite, fontSize: 11},
                          '',
                          {text: this.summary.quotation.other_cost, ...columnWhite, fontSize: 11}
                        ],
                      ],
                    },
                    
                    layout: {
                      defaultBorder: false,
                    }
                  },
                  //End Quotation
                  //Master Plan & Schedule 15
                  {
                    text: "6. "+this.$t("MasterPlanAndSchedule"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 10, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.$t("StartDate")+":\t", bold: true},
                          { text: this.convertToFormatDate(this.summary.master_plan_and_schedule.start_date)+"\n"},
                        ],
                        width: "50%",
                      },
                      {
                        text: [
                          { text: this.$t("FinishDate")+":\t", bold: true },
                          { text: this.convertToFormatDate(this.summary.master_plan_and_schedule.finish_date)+"\n"},
                        ],
                        width: "50%"
                      },
                    ],
                  },
                  {
                    margin: [0, 7, 0, 0],
                    columns: [
                      {
                        text: [
                          { text: this.$t("FirstDemo")+":\t", bold: true},
                          { text: this.convertToFormatDate(this.summary.master_plan_and_schedule.first_demo)+"\n"},
                        ],
                        width: "50%",
                      },
                      {
                        text: [
                          { text: this.$t("UAT")+":\t", bold: true },
                          { text: this.convertToFormatDate(this.summary.master_plan_and_schedule.UAT)+"\n"},
                        ],
                        width: "50%"
                      },
                    ],
                    width: "100%",
                  },
                  //End Master Plan & Schedule
                  //Development 16
                  //Part 2 Development
                  {
                    columns: [
                      {
                        text: this.$t("DevelopmentEffortEstimation").toUpperCase(), fontSize: 22, bold: true, alignment: 'center', margin: [0, 25, 0, 0],
                      },
                    ],
                    pageBreak: 'before'
                  },
                  //The estimation will based on 17
                  {
                    margin: [0, 15, 0, 0],
                    layout: 'lightHorizontalLines', // optional
                    table: {
                      // headers are automatically repeated if the table spans over multiple pages
                      // you can declare how many rows should be treated as headers
                      headerRows: 0,
                      widths: [ '36%', '36%', '35%' ],

                      body: [
                        [ {text: this.$t("TheEstimationWillBasedOn"), ...configHeader},
                          {text: this.$t("WeeklyDayOff"), ...configHeader},
                          {text: this.$t("WorkingHours"), ...configHeader}
                        ],
                        [ {text: this.project_details.estimation_base === '0' ? this.$t("man-hour") : this.$t("man-day"), ...columnWhite, border: [true, false, true, true],},
                          {text: this.project_details.weekends === '0' ? this.$t("Weekend") : this.$t("Sunday"), ...columnWhite, border: [false, false, true, true],},
                          {text: this.project_details.working_hours+" "+this.$t("Hours"), ...columnWhite, border: [false, false, true, true],}
                        ],
                      ],
                    },
                    layout: {
                      defaultBorder: false,
                    },
                  },
                  // Preparation 19
                  {
                    text: "1. "+this.$t("Preparation"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 15, 0, 0],
                    layout: 'lightHorizontalLines',
                    table: {
                      headerRows: 0,
                      widths: [ '15%', '42%', '15%', '20%', '15%' ],

                      body: [
                        [ {text: "#",  ...configHeader},
                          {text: this.$t("WorkItem"), ...configHeader},
                          {text: this.$t("StartDate"), ...configHeader },
                          {text: this.$t("FinishDate"), ...configHeader },
                          {text: this.$t("Total"), ...configHeader },
                        ],
                      ],
                    },
                    layout: {
                      defaultBorder: false,
                    },
                  },
                  // Implementation 21
                  {
                    text: "2. "+this.$t("Implementation"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 15, 0, 0],
                    layout: 'lightHorizontalLines',
                    table: {
                      headerRows: 0,
                      widths: [ '9%', '21%', '12%', '12%', '12%', '8%', '6%', '6%', '7%', '8.5%', '5.5%' ],

                      body: [
                        [ {text: "#", ...configHeader, rowSpan: 3,},
                          {text: this.$t("WorkItem"), rowSpan: 3, ...configHeader, },
                          {text: this.$t("StartDate"), rowSpan: 3, ...configHeader, },
                          {text: this.$t("FinishDate"), rowSpan: 3, ...configHeader, },
                          {text: this.$t("Effort"), colSpan: 5, ...configHeader, border: [true, false, true, true] },
                          '','', '', '',
                          {text: this.$t("Quantity"), rowSpan: 3, ...configHeader, },
                          {text: this.$t("Total"), rowSpan: 3, ...configHeader, },
                        ],
                        [ '',
                          '',
                          '',
                          '',
                          {text: this.$t("RequirementAnalysis"), ...configHeader, fontSize: 8, rowSpan: 2, },
                          {text: this.$t("SystemDesign"), ...configHeader, fontSize: 8, rowSpan: 2, },
                          {text: this.$t("Coding"), colSpan: 2, ...configHeader, fontSize: 8, border: [true, false, true, true] },
                          '',
                          {text: this.$t("Testing/BugsFixing"), ...configHeader, fontSize: 8, rowSpan: 2, },
                          '',
                          ''
                        ],
                        [ '',
                          '',
                          '',
                          '',
                          '',
                          '',
                          {text: this.$t("FE"), ...configHeader, fontSize: 7, },
                          {text: this.$t("BE"), ...configHeader, fontSize: 7, },
                          '',
                          '',
                          ''
                        ],
                        [ {text: this.$t("RequirementAnalysis"), ...configTitle, colSpan: 11, },
                          '','','','','','','','','','',
                        ],
                      ],
                    },
                    layout: {
                      defaultBorder: false,
                    },
                  },
                  // Delivery 23
                  {
                    text: "3. "+this.$t("Delivery"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 15, 0, 0],
                    layout: 'lightHorizontalLines',
                    table: {
                      headerRows: 0,
                      widths: [ '14%', '34%', '12%', '12%', '10%', '25%' ],

                      body: [
                        [ {text: "#",  ...configHeader},
                          {text: this.$t("WorkItem"), ...configHeader},
                          {text: this.$t("StartDate"), ...configHeader },
                          {text: this.$t("FinishDate"), ...configHeader },
                          {text: this.$t("Total"), ...configHeader },
                          {text: this.$t("Note"), ...configHeader },
                        ],
                      ],
                    },
                    layout: {
                      defaultBorder: false,
                    },
                  },
                  // Summary 25
                  {
                    text: "4. "+this.$t("Summary"), fontSize: 13, bold: true, margin: [0, 15, 0, 0],
                  },
                  {
                    margin: [0, 15, 0, 0],
                    layout: 'lightHorizontalLines',
                    table: {
                      headerRows: 0,
                      widths: [ '40%', '17%', '17%', '17%', '16%' ],

                      body: [
                        [ {text: this.$t("Task"), ...configHeader},
                          {text: this.$t("StartDate"), ...configHeader },
                          {text: this.$t("FinishDate"), ...configHeader },
                          {text: this.$t("Scale"), ...configHeader },
                          {text: this.$t("Total"), ...configHeader },
                        ],
                        [ {text: this.$t("Development"), ...configTitle, colSpan: 4, },
                          '','','',{text: this.total_development, ...configTitle},
                        ],
                        [ {text: this.$t("Preparation"), ...columnWhite, },
                          {text: this.convertToFormatDate(preparation.start_date), ...columnWhite, },
                          {text: this.convertToFormatDate(preparation.finish_date), ...columnWhite, },
                          {text: '', ...columnGray, },
                          {text: this.total_preparation, ...columnWhite},
                        ],
                        [ {text: this.$t("Implementation"), ...columnWhite, },
                          {text: this.convertToFormatDate(this.$store.state.development.start_date), ...columnWhite, },
                          {text: this.convertToFormatDate(this.$store.state.development.finish_date), ...columnWhite, },
                          {text: '', ...columnGray, },
                          {text: this.total_implementation, ...columnWhite},
                        ],
                        [ {text: this.$t("Delivery"), ...columnWhite, },
                          {text: this.convertToFormatDate(delivery.start_date), ...columnWhite, },
                          {text: this.convertToFormatDate(delivery.finish_date), ...columnWhite, },
                          {text: '', ...columnGray, },
                          {text: this.total_delivery, ...columnWhite},
                        ],
                        [ {text: this.$t("Warranty", [this.summary.warranty.days]), ...configTitle, colSpan: 4, },
                          '','','',{text: this.total_warranty, ...configTitle},
                        ],
                        [ {text: this.$t("FixLostBugsAndIssues"), ...columnWhite, },
                          {text: this.convertToFormatDate(this.summary.warranty.start_date), ...columnWhite, },
                          {text: this.convertToFormatDate(this.summary.warranty.finish_date), ...columnWhite, },
                          {text: this.formatFloat(this.summary.warranty.scale) + '%', ...columnWhite, },
                          {text: this.total_warranty, ...columnWhite},
                        ],
                        [ {text: this.$t("ProjectManagement") + ' & '+this.$t("SQA"), ...configTitle, colSpan: 4, },
                          '','','',{text: this.formatFloat(parseFloat(this.total_project_management) + parseFloat(this.total_sqa)), ...configTitle},
                        ],
                        [ {text: this.$t("ProjectManagement"), ...columnWhite, },
                          {text: '', ...columnGray, },
                          {text: '', ...columnGray, },
                          {text: this.formatFloat(this.summary.project_managerment_sqa.project_management.scale) + '%', ...columnWhite, },
                          {text: this.total_project_management, ...columnWhite},
                        ],
                        [ {text: this.$t("SQA"), ...columnWhite, },
                          {text: '', ...columnGray, },
                          {text: '', ...columnGray, },
                          {text: this.formatFloat(this.summary.project_managerment_sqa.sqa.scale) + '%', ...columnWhite, },
                          {text: this.total_sqa, ...columnWhite},
                        ],
                        [ {text: this.$t("TotalProjectEffort"), ...configFooter, },
                          {text: this.convertToFormatDate(this.summary.master_plan_and_schedule.start_date), ...configFooter, },
                          {text: this.convertToFormatDate(this.summary.warranty.finish_date), ...configFooter, },
                          {text: '', ...configFooter, },
                          {text: this.project_details.estimation_base === '0' ? this.total_hours : this.total_days, ...configFooter},
                        ],
                      ],
                    },
                    layout: {
                      defaultBorder: false,
                    },
                  },
                ],
              };
              //Add tasks to table Preparation
              if(preparation_tasks.length > 0) {
                for(const [index, value] of preparation_tasks.entries()) {
                  if(index % 2 == 0) {
                    docDefinition.content[18].table.body.push(
                      [ {text: value.index,  ...columnGray },
                        {text: value.task_description, ...columnGray},
                        {text: value.start_date, ...columnGray},
                        {text: value.finish_date, ...columnGray},
                        {text: this.formatFloat(value.total), ...columnGray, border: [false, false, true, false]},
                      ],
                    );
                  } else {
                    docDefinition.content[18].table.body.push(
                      [ {text: value.index, ...columnWhite,},
                        {text: value.task_description, ...columnWhite},
                        {text: value.start_date, ...columnWhite},
                        {text: value.finish_date, ...columnWhite},
                        {text: this.formatFloat(value.total), ...columnWhite, border: [false, false, false, false]},
                      ],
                    );
                  }
                }
              }
              docDefinition.content[18].table.body.push(
                [ {text: this.$t("Total"), colSpan: 2, ...configFooter },
                  '',
                  {text: this.convertToFormatDate(preparation.start_date), ...configFooter},
                  {text: this.convertToFormatDate(preparation.finish_date), ...configFooter},
                  {text: this.total_preparation, ...configFooter},
                ],
              );
              //Add tasks to table Requirements Analysis
              if(requirements_analysis_tasks.length > 0) {
                for(const [index, value] of requirements_analysis_tasks.entries()) {
                  if(index % 2 == 0) {
                    docDefinition.content[20].table.body.push(
                      [ 
                        {text: value.index,  ...columnGray },
                        {text: value.task_description, ...columnGray},
                        {text: value.start_date, ...columnGray},
                        {text: value.finish_date, ...columnGray},
                        {text: this.editHoursDays(value.effort.requirements_analysis), ...columnGray},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: value.quantity, ...columnGray, border: [false, false, true, false]},
                        {text: this.formatFloat(value.total), ...columnGray, border: [false, false, false, false]},
                      ],
                    );
                  } else {
                    docDefinition.content[20].table.body.push(
                      [ 
                        {text: value.index,  ...columnWhite },
                        {text: value.task_description, ...columnWhite},
                        {text: value.start_date, ...columnWhite},
                        {text: value.finish_date, ...columnWhite},
                        {text: this.editHoursDays(value.effort.requirements_analysis), ...columnWhite},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: '', fillColor: '#F5F4F6'},
                        {text: value.quantity, ...columnWhite, border: [false, false, true, false]},
                        {text: this.formatFloat(value.total), ...columnWhite, border: [false, false, false, false]},
                      ],
                    );
                  }
                }
                docDefinition.content[20].table.body.push(
                  [ 
                    {text: this.$t("SubTotal"), colSpan: 2,  ...configFooter },
                    '',
                    {text: this.convertToFormatDate(requirements_analysis.start_date), ...configFooter},
                    {text: this.convertToFormatDate(requirements_analysis.finish_date), ...configFooter},
                    {text: this.total_ra, ...configFooter},
                    {text: '', ...configFooter, colSpan: 5,},
                    '',
                    '',
                    '',
                    '',
                    {
                      text: this.total_ra, ...configFooter, border: [false, false, false, true]
                    },
                  ],
                );
              }
              docDefinition.content[20].table.body.push(
                [ {text: this.$t("ProgrammingAndTesting"), ...configTitle, colSpan: 11, },
                  '','','','','','','','','','',
                ],
              );
              //Add tasks to table programming and testing
              if(programming_and_testing_tasks.length > 0) {
                for(const [index, value] of programming_and_testing_tasks.entries()) {
                  if(index % 2 == 0) {
                    const effort_pat = [ 
                      {text: value.index,  ...columnGray },
                      {text: value.task_description, ...columnGray},
                      {text: value.start_date, ...columnGray},
                      {text: value.finish_date, ...columnGray},
                    ];
                    for (const val_effort of key_effort) {
                      const obj_for = {text: this.editHoursDays(value.effort[val_effort]),...columnGray};
                      effort_pat.push(obj_for);
                    }
                    effort_pat.push(
                      {text: value.quantity, ...columnGray, border: [false, false, true, false]},
                      {text: value.total, ...columnGray, border: [false, false, false, false]},
                    );
                    docDefinition.content[20].table.body.push(
                      effort_pat
                    );
                  } else {
                    const effort_pat = [ 
                      {text: value.index,  ...columnWhite },
                      {text: value.task_description, ...columnWhite},
                      {text: value.start_date, ...columnWhite},
                      {text: value.finish_date, ...columnWhite},
                    ];
                    for (const val_effort of key_effort) {
                      const obj_for = {text: this.editHoursDays(value.effort[val_effort]),...columnWhite};
                      effort_pat.push(obj_for);
                    }
                    effort_pat.push(
                      {text: value.quantity, ...columnWhite, border: [false, false, true, false]},
                      {text: value.total, ...columnWhite, border: [false, false, false, false]},
                    );
                    docDefinition.content[20].table.body.push(
                      effort_pat
                    );
                  }
                }
                const effort_pat_total = [ 
                  {text: this.$t("SubTotal"), colSpan: 2,  ...configFooter },
                  '',
                  {text: this.convertToFormatDate(programming_and_testing.start_date), ...configFooter},
                  {text: this.convertToFormatDate(programming_and_testing.finish_date), ...configFooter},
                ];
                for (const value of key_effort) {
                  const obj_for = {text: this.editHoursDays(programming_and_testing[value]),...configFooter};
                  effort_pat_total.push(obj_for);
                }
                effort_pat_total.push(
                  {text: '',...configFooter},
                  {
                    text: this.total_pat, ...configFooter, border: [false, false, false, false]
                  },
                );
                docDefinition.content[20].table.body.push(
                  effort_pat_total,
                  [ 
                    {text: this.$t("Total"), colSpan: 2, ...configFooter, border: [false, true, true, false] },
                    '',
                    {text: this.convertToFormatDate(this.$store.state.development.start_date), ...configFooter, border: [false, true, true, false]},
                    {text: this.convertToFormatDate(this.$store.state.development.finish_date), ...configFooter, border: [false, true, true, false]},
                    {text: '', colSpan: 6, ...configFooter, border: [false, true, true, false]}, '', '', '', '', '', 
                    {text: this.total_implementation, ...configFooter, border: [false, true, true, false]},
                  ],
                );
              }
              //Add tasks to table Delivery
              if(delivery_tasks.length > 0) {
                for(const [index, value] of delivery_tasks.entries()) {
                  if(index % 2 == 0) {
                    docDefinition.content[22].table.body.push(
                      [ {text: value.index,  ...columnGray },
                        {text: value.task_description, ...columnGray},
                        {text: value.start_date, ...columnGray},
                        {text: value.finish_date, ...columnGray},
                        {text: this.formatFloat(value.total), ...columnGray, border: [false, false, true, false]},
                        {text: value.note, ...columnGray},
                      ],
                    );
                  } else {
                    docDefinition.content[22].table.body.push(
                      [ {text: value.index, ...columnWhite,},
                        {text: value.task_description, ...columnWhite},
                        {text: value.start_date, ...columnWhite},
                        {text: value.finish_date, ...columnWhite},
                        {text: this.formatFloat(value.total), ...columnWhite, border: [false, false, false, false]},
                        {text: value.note, ...columnWhite},
                      ],
                    );
                  }
                }
              }
              docDefinition.content[22].table.body.push(
                [ {text: this.$t("Total"), colSpan: 2, ...configFooter },
                  '',
                  {text: this.convertToFormatDate(delivery.start_date), ...configFooter},
                  {text: this.convertToFormatDate(delivery.finish_date), ...configFooter},
                  {text: this.total_delivery, ...configFooter},
                  {text: '', ...configFooter},
                ],
              );
              //Summary
              var pdfDoc = pdfMake.createPdf(docDefinition);
              pdfDoc.download(this.summary.project_name.project_name+".pdf");
              if(confirm(this.$t("ConfirmStoreJson"))) {
                const jsonString = JSON.stringify({
                  work: this.$store.state.work,
                  preparation: this.$store.state.preparation,
                  development: this.$store.state.development,
                  delivery: this.$store.state.delivery,
                }, null, 2);
                const blob = new Blob([jsonString], { type: 'application/json' });
                saveAs(blob, this.summary.project_name.project_name+'.json');
              }
              this.$store.dispatch("notification/enableNotification", {
                title: this.$t("Success"),
                message: this.$t("SuccessImportFile", ['PDF']),
                type: "success",
                from: "top",
                align: "right",
                time: 1000,
              });
            };
            reader.readAsDataURL(blob);
          }
        }
      } catch (err) {
        console.log(err.message);
        this.$store.dispatch("notification/enableNotification", {
          title: this.$t("TitleErrorExport", ['PDF']),
          message: this.$t("MessageErrorExport", ['PDF']),
          type: "danger",
          from: "top",
          align: "right",
          time: 1000,
        });
      }
    },
    async handleExportExcel() {
      try {
        const preparation_tasks = [];
        service.convertTasksToArray(this.preparation.task, preparation_tasks, this.project_details, 'pre', 'yyyy-MM-dd');
        const requirements_analysis_tasks = [];
        service.convertTasksToArray(this.requirements_analysis.task, requirements_analysis_tasks, this.project_details, 'dev', 'yyyy-MM-dd');
        const programming_and_testing_tasks = [];
        service.convertTasksToArray(this.programming_and_testing.task, programming_and_testing_tasks, this.project_details, 'dev', 'yyyy-MM-dd');
        const delivery_tasks = [];
        service.convertTasksToArray(this.delivery.task, delivery_tasks, this.project_details, 'del', 'yyyy-MM-dd');
        const workbook = new ExcelJS.Workbook();
        // Loại bỏ border cho toàn bộ worksheet
        if(!service.checkBeforeConvertToPDF({
          preparation: this.preparation, 
          requirements_analysis: this.requirements_analysis, 
          programming_and_testing: this.programming_and_testing,
          delivery: this.delivery,
          summary: this.summary,
        })) {
          const button = document.getElementById("id-ErrorBeforeConvertToExcelPDF");
          button.click();
        } else {
          const imagePath = require("../assets/img/logo/logo.png");
          const imageElement = new Image();
          imageElement.src = imagePath;
          imageElement.onload = async () => {
            try {
              const dataImage = await fetch(imagePath)
              const blob = await dataImage.blob();
              const reader = new FileReader();
              reader.onload = async () => {
                const base64String = reader.result;
                const width = imageElement.width;
                const height = imageElement.height;
                const desiredHeight = height >= 43 ? 43 : height;
                const aspectRatio = width / height;
                const calculatedWidth = desiredHeight * aspectRatio;
                const development_object = {
                  preparation: preparation_tasks,
                  requirements_analysis: requirements_analysis_tasks,
                  programming_and_testing: programming_and_testing_tasks,
                  delivery: delivery_tasks,
                  preparation_details: {
                    start_date: this.preparation.start_date,
                    finish_date: this.preparation.finish_date,
                    hours: this.preparation.hours,
                    days: this.preparation.days
                  },
                  requirements_analysis_details: {
                    start_date: this.requirements_analysis.start_date,
                    finish_date: this.requirements_analysis.finish_date,
                    hours: this.requirements_analysis.hours,
                    days: this.requirements_analysis.days
                  },
                  programming_and_testing_details: {
                    start_date: this.programming_and_testing.start_date,
                    finish_date: this.programming_and_testing.finish_date,
                    hours: this.programming_and_testing.hours,
                    days: this.programming_and_testing.days,
                    requirements_analysis: this.programming_and_testing.requirements_analysis,
                    system_design: this.programming_and_testing.system_design,
                    FE: this.programming_and_testing.FE,
                    BE: this.programming_and_testing.BE,
                    testing_bugs_fixing: this.programming_and_testing.testing_bugs_fixing,
                  },
                  implementation_details: {
                    start_date: this.$store.state.development.start_date,
                    finish_date: this.$store.state.development.finish_date,
                    hours: this.$store.state.development.hours,
                    days: this.$store.state.development.days,
                  },
                  development_details: {
                    total: this.total_development,
                    project_total: this.project_details.estimation_base === '0' ? 
                    this.total_hours : this.total_days,
                    months_total: this.total_months,
                  },
                  delivery_details: {
                    start_date: this.delivery.start_date,
                    finish_date: this.delivery.finish_date,
                    hours: this.delivery.hours,
                    days: this.delivery.days
                  },
                  summary: this.summary,
                  total_project_management: this.formatFloat(parseFloat(this.total_project_management) + parseFloat(this.total_sqa)),
                }
                const summary = workbook.addWorksheet("Summary");
                const imageId = workbook.addImage({
                  base64: base64String,
                  extension: 'png',
                });
                summary.addImage(imageId, {
                  tl: { col: 1, row: 1 },
                  ext: { width: calculatedWidth*3/5, height: desiredHeight*3/5 }
                });
                //Image and Company Information Summary
                summary.mergeCells("C2:E4");
                summary.getCell("C2:E4").alignment = { vertical: 'top', horizontal: "left", wrapText: true };
                summary.getCell("C2:E4").value = this.companyName+"\n"+this.companyAddress+"\n"+this.companyContact;
                setFrameSummary(summary, this.summary, this.$t, this.project_details);
                //End
                
                const development = workbook.addWorksheet("Development effort");
                development.addImage(imageId, {
                  tl: { col: 2, row: 1 },
                  ext: { width: calculatedWidth*4/5, height: desiredHeight*4/5 }
                });
                //Image and Company Information Development
                development.mergeCells("D2:J4");
                development.getCell("D2:J4").alignment = { vertical: 'top', horizontal: "left", wrapText: true };
                development.getCell("D2:J4").value = this.companyName+"\n"+this.companyAddress+"\n"+this.companyContact;
                setFrameDevelopment(development, development_object, this.$t, this.project_details);
                resetCount();
                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, this.summary.project_name.project_name+'.xlsx');
                if(confirm(this.$t("ConfirmStoreJson"))) {
                  const jsonString = JSON.stringify({
                    work: this.$store.state.work,
                    preparation: this.$store.state.preparation,
                    development: this.$store.state.development,
                    delivery: this.$store.state.delivery,
                  }, null, 2);
                  const blob = new Blob([jsonString], { type: 'application/json' });
                  saveAs(blob, this.summary.project_name.project_name+'.json');
                }
                this.$store.dispatch("notification/enableNotification", {
                  title: this.$t("Success"),
                  message: this.$t("SuccessImportFile", ['Excel']),
                  type: "success",
                  from: "top",
                  align: "right",
                  time: 1000,
                });
              }
              reader.readAsDataURL(blob);
            } catch (err) {
              console.log(err.message);
              this.$store.dispatch("notification/enableNotification", {
                title: this.$t("TitleErrorExport", ['Excel']),
                message: this.$t("MessageErrorExport", ['Excel']),
                type: "danger",
                from: "top",
                align: "right",
                time: 1000,
              });
            }
          }
        }
      } catch (err) {
        console.log(err.message);
        this.$store.dispatch("notification/enableNotification", {
          title: this.$t("TitleErrorExport", ['Excel']),
          message: this.$t("MessageErrorExport", ['Excel']),
          type: "danger",
          from: "top",
          align: "right",
          time: 1000,
        });
      }
    },
    handleImportFile(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const fileContent = e.target.result;
            const encodedString = fileContent.substring(29);
            const decodedData = Uint8Array.from(atob(encodedString), c => c.charCodeAt(0));
            const textDecoder = new TextDecoder('utf-8');
            const jsonString = textDecoder.decode(decodedData);
            const json = service.isJSON(jsonString);
            if (json) {
              let flag = this.checkJsonFormat(json, true);
              if(flag) {
                this.json_content = json;
                this.file_name = file.name;
                this.error_json = "";
              } else {
                this.error_json = this.$t("ErrorFileJson");
              }
            } else {
              this.$store.dispatch("notification/enableNotification", {
                title: this.$t("TitleErrorFileJson"),
                message: this.$t("ErrorFileJson"),
                type: "danger",
                from: "top",
                align: "right",
                time: 1000,
              });
            }
          } catch (e) {
            console.log(e.message);
            this.error_json = this.$t("ErrorFileJson");
          }
        }
        reader.readAsDataURL(file);
      }
    },
    handleImportData() {
      if(this.json_content) {
        let flag = this.checkJsonFormat(this.json_content, true);
        if(flag) {
          this.$store.dispatch('work/importDataEstimation', this.json_content);
          this.$store.dispatch("notification/enableNotification", {
            title: this.$t("Success"),
            message: this.$t("ImportSuccessFile"),
            type: "success",
            from: "top",
            align: "right",
            time: 1000,
          });
          this.$refs.modalImportData.handleCloseModal();
          this.error_json = "";
        } else {
          this.error_json = this.$t("ErrorFileJson");
        }
      }
    },
    handleResetData() {
      if(confirm(this.$t("ResetData"))) {
        this.$store.dispatch('work/resetDateEstimation', {
          work: this.work_base,
          preparation: this.preparation_base,
          development: this.development_base,
          delivery: this.delivery_base,
        });
        this.$store.dispatch("notification/enableNotification", {
          title: this.$t("Success"),
          message: this.$t("SuccessDeleteFile"),
          type: "success",
          from: "top",
          align: "right",
          time: 1000,
        });
      }
    },
    checkJsonFormat(json, flag) {
      if('work' in json && flag) {
        if (!service.haveSameKeys(json['work'], this.work_base))  flag = false;
      } else  flag = false;
      if('preparation' in json && flag) {
        if (!service.haveSameKeys(json['preparation'], this.preparation_base))  flag = false;
      } else  flag = false;
      if('development' in json && flag) {
        if (!service.haveSameKeys(json['development'], this.development_base))  flag = false;
      } else  flag = false;
      if('delivery' in json && flag) {
        if (!service.haveSameKeys(json['delivery'], this.delivery_base))  flag = false;
      } else  flag = false;
      return flag;
    }
  },
};
</script>
<style scoped>
.sidebar-content {
  margin-top: 40px;
}
.current-page {
  background: rgba(199, 199, 199, 0.2);
}
.current-page p,
.current-page i {
  color: #575962 !important;
  font-weight: 600;
}
</style>
