import { format, eachDayOfInterval, isSunday, isSaturday } from 'date-fns';
import { timeService } from "@/services";
export const patService = {
  updateStartFinishDatePAT,
  updateLastStartFinishDatePAT,
  updatePAT,
  updateTaskHoursPAT,
  updateTaskDaysPAT,
  updateAfterChangeWorkingHours
};

function updateStartFinishDatePAT(object, settings) {
  for (const key in object) {
    if (object[key].sub_task && Object.keys(object[key].sub_task).length > 0) {
      updateStartFinishDatePAT(object[key].sub_task, settings);
      let minStartDate = new Date(8640000000000000);
      let maxFinishDate = new Date(-8640000000000000);
      let hours = 0;
      let info_pat = {
        requirements_analysis: {
          days: 0,
          hours: 0,
        },
        system_design: {
          days: 0,
          hours: 0,
        },
        FE: {
          days: 0,
          hours: 0,
        },
        BE: {
          days: 0,
          hours: 0,
        },
        testing_bugs_fixing: {
          days: 0,
          hours: 0,
        },
      };
      try {
        for(const sub_key in object[key].sub_task){
          for(const per_sub_task in object[key].sub_task[sub_key].effort) {
            info_pat[per_sub_task].hours += parseFloat(object[key].sub_task[sub_key].effort[per_sub_task].hours);
            info_pat[per_sub_task].days += parseFloat(object[key].sub_task[sub_key].effort[per_sub_task].days);
          }
          const currentSubTaskStartDate = new Date(object[key].sub_task[sub_key].start_date);
          const currentSubTaskFinishDate = new Date(object[key].sub_task[sub_key].finish_date);
          if(currentSubTaskStartDate < minStartDate) minStartDate = currentSubTaskStartDate;
          if(currentSubTaskFinishDate > maxFinishDate) maxFinishDate = currentSubTaskFinishDate;
          hours += parseFloat(object[key].sub_task[sub_key].hours);
        }
        if(isNaN(hours) || !hours)  throw new Error("Invalid total of hours");
        object[key].start_date = format(minStartDate, "yyyy-MM-dd");
        object[key].finish_date = format(maxFinishDate, "yyyy-MM-dd");
        object[key].hours = hours.toString();
        object[key].days = parseFloat(hours/parseInt(settings.working_hours)).toString();
        for(const sub_key in info_pat){
          object[key].effort[sub_key].hours = info_pat[sub_key].hours.toString();
          object[key].effort[sub_key].days = info_pat[sub_key].days.toString();
        }
      } catch (e) {
        console.log(e.message);
      }
    } else {
      try {
        const start_date = new Date(object[key].start_date);
        const finish_date = new Date(object[key].finish_date);
        const allDays = eachDayOfInterval({ start: start_date, end: finish_date });
        let workingDays = allDays.filter(day => !isSunday(day)).length;
        if(settings.weekends === "0") {
          workingDays = allDays.filter(day => !isSaturday(day) && !isSunday(day)).length;
        }
        let hours = 0;
        for(const type_key in object[key].effort){
          hours += parseFloat(object[key].effort[type_key].hours);
        }
        const current_hours_parse_days = Math.ceil(parseFloat(hours) / parseFloat(settings.working_hours)); //số ngày dựa vào hours thực tế
        if(current_hours_parse_days != workingDays) {
          for(const type_key in object[key].effort){
            object[key].effort[type_key].hours = (workingDays*parseFloat(settings.working_hours)/5).toString();
            object[key].effort[type_key].days = (workingDays/5).toString();
          }
          object[key].hours = (workingDays*parseFloat(settings.working_hours)).toString();
          object[key].days = workingDays.toString();
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }
}

function updateLastStartFinishDatePAT(object, parent, root) {
  let minStartDate = new Date(8640000000000000);
  let maxFinishDate = new Date(-8640000000000000);
  let hours = 0;
  let days = 0;
  let info_pat = {
    requirements_analysis: {
      days: 0,
      hours: 0,
    },
    system_design: {
      days: 0,
      hours: 0,
    },
    FE: {
      days: 0,
      hours: 0,
    },
    BE: {
      days: 0,
      hours: 0,
    },
    testing_bugs_fixing: {
      days: 0,
      hours: 0,
    },
  };
  try {
    if(Object.keys(object).length > 0) {
      for (const key in object) {
        for(const per_sub_task in object[key].effort) {
          info_pat[per_sub_task].hours += parseFloat(object[key].effort[per_sub_task].hours);
          info_pat[per_sub_task].days += parseFloat(object[key].effort[per_sub_task].days);
        }
        const currentSubTaskStartDate = new Date(object[key].start_date);
        const currentSubTaskFinishDate = new Date(object[key].finish_date);
        if(currentSubTaskStartDate < minStartDate) minStartDate = currentSubTaskStartDate;
        if(currentSubTaskFinishDate > maxFinishDate) maxFinishDate = currentSubTaskFinishDate;
        hours += parseFloat(object[key].hours);
        days += parseFloat(object[key].days);
      }
      for(const sub_key in info_pat){
        parent[sub_key].hours = info_pat[sub_key].hours.toString();
        parent[sub_key].days = info_pat[sub_key].days.toString();
      }
      parent.start_date = format(minStartDate, "yyyy-MM-dd");
      parent.finish_date = format(maxFinishDate, "yyyy-MM-dd");
      parent.hours = hours.toString();
      parent.days = days.toString();
    }else {
      resetParent(parent);
      const arr_keys = ['requirements_analysis', 'system_design', 'FE', 'BE', 'testing_bugs_fixing'];
      for(const key of arr_keys){
        parent[key].hours = "";
        parent[key].days = "";
      }
      if(Object.keys(root.requirements_analysis.task).length === 0){
        resetParent(root.requirements_analysis);
        resetParent(root);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

function resetParent(parent) {
  parent.start_date = "";
  parent.finish_date = "";
  parent.hours = "";
  parent.days = "";
}

function updatePAT(object, parent, settings, root) {
  updateStartFinishDatePAT(object, settings);
  updateLastStartFinishDatePAT(object, parent, root);
}
function updateTaskHoursPAT(obj, targetIndex, type, settings) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (obj[key].index === targetIndex) {
        if(!obj[key].effort[type].hours)  obj[key].effort[type].hours = (parseFloat(settings.working_hours) / 5).toString();
        try {
          let hours = 0;
          for(const type_key in obj[key].effort){
            hours += parseFloat(obj[key].effort[type_key].hours);
          }
          if(isNaN(hours)) throw new Error("Invalid hours");
          let days = hours / parseFloat(settings.working_hours);
          const current_date = new Date(obj[key].start_date);
          let finish_date = current_date;
          finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
          obj[key].finish_date = format(finish_date, "yyyy-MM-dd");
          obj[key].hours = hours;
          obj[key].days = days.toString();
          obj[key].effort[type].days = (parseFloat(obj[key].effort[type].hours) / 
          parseFloat(settings.working_hours)).toString();
          return true;
        } catch (e) {
          console.log(e.message);
          resetError(obj[key], settings);
        }
      } else if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
        if(updateTaskHoursPAT(obj[key].sub_task, targetIndex, type, settings))
          return true;
      }
    }
  }
  return false;
}
function updateTaskDaysPAT(obj, targetIndex, type, settings) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (obj[key].index === targetIndex) {
        try {
          if(!obj[key].effort[type].days)  obj[key].effort[type].days = '0.2';
          //Lấy ra các biến giờ của task hiện tại và day của settings
          let days = 0;
          for(const type_key in obj[key].effort){
            days += parseFloat(obj[key].effort[type_key].days);
          }
          if(isNaN(days)) throw new Error("Invalid days");
          let working_hours = settings.working_hours;
          const current_date = new Date(obj[key].start_date);
          let finish_date = current_date;
          finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
          obj[key].finish_date = format(finish_date, "yyyy-MM-dd");
          obj[key].hours = (days*working_hours).toString();
          obj[key].days = days.toString();
          obj[key].effort[type].hours = (parseFloat(obj[key].effort[type].days) * 
          parseFloat(settings.working_hours)).toString();
          return true;
        } catch (e) {
          console.log(e.message);
          resetError(obj[key], settings);
        }
      } else if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
        if(updateTaskDaysPAT(obj[key].sub_task, targetIndex, settings))
          return true;
      }
    }
  }
  return false;
}
function updateAfterChangeWorkingHours(obj, settings) {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      //Lấy ra các biến giờ của task hiện tại và day của settings
      try {
        let hours = 0;
        const working_hours = parseFloat(settings.working_hours);
        for(const type_key in obj[key].effort){
          hours += parseFloat(obj[key].effort[type_key].hours);
          obj[key].effort[type_key].days = (parseFloat(obj[key].effort[type_key].hours) / working_hours).toString();
        }
        if(isNaN(hours)) throw new Error("Invalid hours");
        let days = hours / working_hours;
        const current_date = new Date(obj[key].start_date);
        let finish_date = current_date;
        finish_date = timeService.addDaysFollowWeekdays(finish_date, current_date, settings, days);
        obj[key].finish_date = format(finish_date, "yyyy-MM-dd");
        obj[key].days = days.toString();
        obj[key].created_at = Math.random().toString(30);
        if (obj[key].sub_task && Object.keys(obj[key].sub_task).length > 0) {
          updateAfterChangeWorkingHours(obj[key].sub_task, settings);
        }
      } catch (e) {
        console.log(e.message);
        resetError(obj[key], settings);
      }
    }
  }
  return true;
}

function resetError(object, settings) {
  object.finish_date = object.start_date;
  for(const type_key in object.effort){
    object.effort[type_key].hours = (parseFloat(settings.working_hours) / 
    parseFloat(5)).toString();
    object.effort[type_key].days = "0.2";
  }
  object.hours = settings.working_hours;
  object.days = "1";
}