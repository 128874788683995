import { preparationService, service, timeService } from "../../services";
const Preparation = {
  namespaced: true,
  state: {
    preparation: {
      next_preparation_id: 1,
      start_date: "",
      finish_date: "",
      hours: "",
      days: "",
      task: {}
    },
    isDeletePreparation: false,
  },
  mutations: {
    //Preparation
    addTaskPreparation(state , sample) {
      sample = JSON.parse(JSON.stringify(sample));
      const prevIndex = Object.keys(state.preparation.task).length;
      const currentIndex = prevIndex + 1;
      const index = "1."+currentIndex
      sample.index = index;
      state.preparation.task[currentIndex] = sample;
    },
    addSubTaskPreparation(state, object) {
      const { index, sample } = object;
      const sample_object = JSON.parse(JSON.stringify(sample));
      service.addObjectByIndex(state.preparation.task, index, sample_object, 1);
    },
    updateTaskPreparation(state) {
      const tmp_object = {};
      service.updateIDTask(state.preparation.task, tmp_object, 1);
      state.preparation.task = tmp_object;
    },
    deleteTaskPreparation(state, index) {
      const isDelete = service.deleteTask(state.preparation.task, index);
      state.isDeletePreparation = isDelete;
    },
    updateTaskHoursPreparation(state, block) {
      const { index, settings } = block;
      preparationService.updateTaskHoursPreparation(state.preparation.task, index, settings);
    },
    updateTaskDaysPreparation(state, block) {
      const { index, settings } = block;
      preparationService.updateTaskDaysPreparation(state.preparation.task, index, settings);
    },
    updateAfterChangeWorkingHours(state, block) {
      const {settings, rootState} = block;
      preparationService.updateAfterChangeWorkingHours(state.preparation.task, settings);
      preparationService.updateLastStartFinishDate(state.preparation.task, state.preparation);
      timeService.setStartDateImplementation(state, rootState, settings);
    },
    updatePreparation(state, rootState) {
      const settings = rootState.work.project_details;
      preparationService.updatePreparation(state.preparation.task, 
        state.preparation, settings
      );
      timeService.setStartDateImplementation(state, rootState, settings);
    },
  },
  actions: {
    addTaskPreparation({ commit, rootState }, sample) {
      commit("addTaskPreparation", sample);
      commit("updatePreparation", rootState);
    },
    addSubTaskPreparation({ commit, rootState }, object) {
      commit("addSubTaskPreparation", JSON.parse(JSON.stringify(object)));
      commit("updatePreparation", rootState);
    },
    deleteTaskPreparation({ commit, state }, index) {
      commit("deleteTaskPreparation", index);
      return state.isDeletePreparation;
    },
    updateTaskPreparation({ commit, rootState }) {
      commit("updateTaskPreparation", rootState.work.project_details);
      commit("updatePreparation", rootState);
    },
    updateTimeLinePreparation({ commit, rootState }) {
      commit("updatePreparation", rootState);
    },
    updateTaskHoursPreparation({ commit, rootState }, index) {
      commit("updateTaskHoursPreparation", { index: index, settings: rootState.work.project_details });
      commit("updatePreparation", rootState);
    },
    updateTaskDaysPreparation({ commit, rootState }, index) {
      commit("updateTaskDaysPreparation", { index: index, settings: rootState.work.project_details });
      commit("updatePreparation", rootState);
    },
    updateAfterChangeWorkingHours({ commit, rootState }) {
      commit("updateAfterChangeWorkingHours", {settings: rootState.work.project_details, rootState: rootState});
      commit("updatePreparation", rootState);
    }
  },
  getters: {},
};

export default Preparation;
