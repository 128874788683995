<template>
  <div class="home">
    <Header />
    <side-bar />
    <div class="main-panel" ref="mainPanel">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <right-bar v-if="isShowChart" />
  </div>
</template>

<script setup>
import "@/assets/js/core/jquery.3.2.1.min.js";
import "@/assets/js/core/popper.min.js";
import "@/assets/js/core/bootstrap.min.js";
import "@/assets/js/setting-demo.js";
</script>
<script>
// @ is an alias to /src
import Header from "@/templates/Header.vue";
import SideBar from "@/templates/SideBar.vue";
import RightBar from "@/views/RightBar.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    SideBar,
    RightBar,
  },
  computed: {
    isShowChart() {
      const condition = ( Object.keys(this.$store.state.preparation.preparation.task).length > 0 ) ||
      ( Object.keys(this.$store.state.development.requirements_analysis.task).length > 0 ) ||
      ( Object.keys(this.$store.state.development.programming_and_testing.task).length > 0 ) ||
      ( Object.keys(this.$store.state.delivery.delivery.task).length > 0 );
      return condition;
    },
  }
};
</script>
