import { service, raService, patService, timeService } from "../../services";
const Development = {
  namespaced: true,
  state: {
    start_date: "",
    finish_date: "",
    hours: "",
    days: "",
    requirements_analysis: {
      start_date: "",
      finish_date: "",
      hours: "",
      days: "",
      task: {}
    },
    programming_and_testing: {
      start_date: "",
      finish_date: "",
      hours: "",
      days: "",
      requirements_analysis: {
        days: "",
        hours: "",
      },
      system_design: {
        days: "",
        hours: "",
      },
      FE: {
        days: "",
        hours: "",
      },
      BE: {
        days: "",
        hours: "",
      },
      testing_bugs_fixing: {
        days: "",
        hours: "",
      },
      task: {},
    },
    isDeleteRA: false,
  },
  mutations: {
    /* Requirement Analysis */
    addTaskRA(state, sample) {
      sample = JSON.parse(JSON.stringify(sample));
      const prevIndex = Object.keys(state.requirements_analysis.task).length;
      const currentIndex = prevIndex + 1;
      const index = "2I."+currentIndex
      sample.index = index;
      state.requirements_analysis.task[currentIndex] = sample;
    },
    addSubTaskRA(state, object) {
      const { index, sample } = object;
      const sample_object = JSON.parse(JSON.stringify(sample));
      service.addObjectByIndex(state.requirements_analysis.task, index, sample_object, '2I');
    },
    deleteTaskRA(state, index) {
      const isDelete = service.deleteTask(state.requirements_analysis.task, index);
      state.isDeleteRA = isDelete;
    },
    updateTaskRA(state) {
      const tmp_object = {};
      service.updateIDTask(state.requirements_analysis.task, tmp_object, '2I');
      state.requirements_analysis.task = tmp_object;
    },
    updateTaskHoursRA(state, block) {
      const { index, settings } = block;
      raService.updateTaskHoursRA(state.requirements_analysis.task, index, settings);
    },
    updateTaskDaysRA(state, block) {
      const { index, settings } = block;
      raService.updateTaskDaysRA(state.requirements_analysis.task, index, settings);
    },
    updateRA(state, rootState) {
      const settings = rootState.work.project_details;
      raService.updateRA(state.requirements_analysis.task, state.requirements_analysis, settings, state);
      service.updateTotalTimeDevelopment(state); //Cập nhật lại tổng thời gian của Development
      timeService.setStartDateProgrammingAndTesting(state, settings); //Cập nhật thời gian bắt đầu của PAT
    },
    updateAfterChangeWorkingHours(state, settings) {
      raService.updateAfterChangeWorkingHours(state.requirements_analysis.task, settings);
      raService.updateLastStartFinishDateRA(state.requirements_analysis.task, state.requirements_analysis);
      service.updateTotalTimeDevelopment(state); //Cập nhật lại tổng thời gian của Development
      timeService.setStartDateProgrammingAndTesting(state, settings); //Cập nhật thời gian bắt đầu của PAT
    },
    /* End Requirement Analysis */

    /* Programming And Testing */
    addTaskPAT(state, sample) {
      sample = JSON.parse(JSON.stringify(sample));
      const prevIndex = Object.keys(state.programming_and_testing.task).length;
      const currentIndex = prevIndex + 1;
      const index = "2II."+currentIndex
      sample.index = index;
      state.programming_and_testing.task[currentIndex] = sample;
    },
    addSubTaskPAT(state, object) {
      const { index, sample } = object;
      const sample_object = JSON.parse(JSON.stringify(sample));
      service.addObjectByIndex(state.programming_and_testing.task, index, sample_object, '2B');
    },
    deleteTaskPAT(state, index) {
      const isDelete = service.deleteTask(state.programming_and_testing.task, index);
      state.isDeleteRA = isDelete;
    },
    updateTaskPAT(state) {
      const tmp_object = {};
      service.updateIDTask(state.programming_and_testing.task, tmp_object, '2II');
      state.programming_and_testing.task = tmp_object;
    },
    updateTaskHoursPAT(state, block) {
      const { index, type, settings } = block;
      patService.updateTaskHoursPAT(state.programming_and_testing.task, index, type, settings);
    },
    updateTaskDaysPAT(state, block) {
      const { index, type, settings } = block;
      patService.updateTaskDaysPAT(state.programming_and_testing.task, index, type, settings);
    },
    updatePAT(state, rootState) {
      const settings = rootState.work.project_details;
      patService.updatePAT(state.programming_and_testing.task, state.programming_and_testing, settings, state);
      service.updateTotalTimeDevelopment(state);
      timeService.setStartDateDelivery(state, rootState, settings);
    },
    updateAfterChangeWorkingHoursPAT(state, block) {
      const {settings, rootState} = block;
      patService.updateAfterChangeWorkingHours(state.programming_and_testing.task, settings);
      raService.updateLastStartFinishDateRA(state.programming_and_testing.task, state.programming_and_testing);
      service.updateTotalTimeDevelopment(state);
      timeService.setStartDateDelivery(state, rootState, settings);
    }
    /* End Programming And Testing */
  },
  actions: {
    /* Requirement Analysis */
    addTaskRA({ commit, rootState }, sample) {
      commit("addTaskRA", sample);
      commit("updateRA", rootState);
    },
    addSubTaskRA({ commit, rootState }, object) {
      commit("addSubTaskRA", JSON.parse(JSON.stringify(object)));
      commit("updateRA", rootState);
    },
    deleteTaskRA({ commit, state }, index) {
      commit("deleteTaskRA", index);
      return state.isDeleteRA;
    },
    updateTaskRA({ commit, rootState }) {
      commit("updateTaskRA", rootState.work.project_details);
      commit("updateRA", rootState);
    },
    updateTimeLineRA({ commit, rootState }) {
      commit("updateRA", rootState);
    },
    updateTaskHoursRA({ commit, rootState }, index) {
      commit("updateTaskHoursRA", { index: index, settings: rootState.work.project_details });
      commit("updateRA", rootState);
    },
    updateTaskDaysRA({ commit, rootState }, index) {
      commit("updateTaskDaysRA", { index: index, settings: rootState.work.project_details });
      commit("updateRA", rootState);
    },
    updateAfterChangeWorkingHours({ commit, rootState }) {
      commit("updateAfterChangeWorkingHours", rootState.work.project_details);
      commit("updateRA", rootState);
    },
    /* End Requirement Analysis */

    /* Programming And Testing */
    addTaskPAT({ commit, rootState }, sample) {
      commit("addTaskPAT", sample);
      commit("updatePAT", rootState);
    },
    addSubTaskPAT({ commit, rootState }, object) {
      commit("addSubTaskPAT", JSON.parse(JSON.stringify(object)));
      commit("updatePAT", rootState);
    },
    deleteTaskPAT({ commit, state }, index) {
      commit("deleteTaskPAT", index);
      return state.isDeleteRA;
    },
    updateTaskPAT({ commit, rootState }) {
      commit("updateTaskPAT", rootState.work.project_details);
      commit("updatePAT", rootState);
    },
    updateTaskHoursPAT({ commit, rootState }, object) {
      const { index, type } = object;
      commit("updateTaskHoursPAT", { index: index, type: type, settings: rootState.work.project_details });
      commit("updatePAT", rootState);
    },
    updateTaskDaysPAT({ commit, rootState }, object) {
      const { index, type } = object;
      commit("updateTaskDaysPAT", { index: index, type: type, settings: rootState.work.project_details });
      commit("updatePAT", rootState);
    },
    updateTimeLinePAT({ commit, rootState }) {
      commit("updatePAT", rootState);
    },
    updateAfterChangeWorkingHoursPAT({ commit, rootState }) {
      commit("updateAfterChangeWorkingHoursPAT", {settings: rootState.work.project_details, rootState: rootState});
      commit("updatePAT", rootState);
    },
    /* End Programming And Testing */
  },
  getters: {},
};

export default Development;
